import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box, Tooltip } from '@material-ui/core';

import {connect, useDispatch, useSelector} from 'react-redux';

import projectLogo from '../../../styles/images/logo_circle.png';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import * as themeActions from "../../../stores/modules/theme/actions";
import {IStoreState} from "../../../stores/createStore";

const SidebarHeader = () => {
  const dispatch = useDispatch();
  const { sidebarToggleMobile } = useSelector((state: IStoreState) => state.theme);

  const toggleSidebarMobile = () => {
    dispatch(themeActions.setSidebarToggleMobile(!sidebarToggleMobile))
  };

  return (
    <Fragment>
      <div className={clsx('app-sidebar-header', {})}>
        <Box
          className="header-logo-wrapper"
          title="ZenOwn">
          <Link to="/" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-sidebar-logo"
                alt="ZenOwn"
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">ZenOwn</Box>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton
              color="inherit"
              onClick={toggleSidebarMobile}
              size="medium">
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </Fragment>
  );
};

export default SidebarHeader;
