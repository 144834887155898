import React, {Fragment,  useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {Button, CircularProgress, Grid, TextField, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";
import { GridColDef, DataGrid, GridToolbarContainer, GridToolbarExport, GridValueFormatterParams  } from '@mui/x-data-grid';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";


const styles = makeStyles(() => ({
  dateSelection: {
    minHeight: 105,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#f5f5f5',
    paddingLeft: 20,
    paddingRight: 20,
    '& h2': {
      fontWeight: 500
    }
  },
  gridContainer: {
    marginBottom: 30
  },
  tableTitle: {
    paddingBottom: 10
  },
  rowTitle: {
    fontWeight: 600
  },
  dataRangeSection: {
    position: "absolute",
    zIndex: 9000,
    padding: 20,
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    right:0
  },
  dataRangeButton: {
    marginTop: 20,
    backgroundColor: '#3d4977',
    color: 'white',
    fontWeight: 700
  },
  inputStyle: {
    width:"100%",
    backgroundColor:'#fff',
    padding:10,
    border:'1px solid #E9EBED',
    '& label': {
      paddingTop: 15,
      paddingLeft: 15,
      color:'#000'
    }
  },
  rangeDatePicker: {
    '& .rdrDateDisplayItem': {
      color:'#000 !important',
      '& input': {
        color:'#000 !important'
      }
    },
    '& .rdrMonthName': {
      color:'#000 !important',
    },
    '& .rdrDayNumber': {
      color:'#000 !important',
    }
  }
}));

export default function KPIsTable () {
  const {
    kpi_data,
    kpi_data_isLoading,
    kpi_data_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const dispatch = useDispatch();

  const classes = styles();


  const [initialDate, setInitialDate] = React.useState(moment().add(-7,'days'));

  const [endDate, setEndDate] = React.useState(moment());

  const handleInitialDateChange = (date: any) => {
    setInitialDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };


  const [overallData, setOverallData] = React.useState([]);
  const [typeData, setTypeData] = React.useState([]);
  const [userData, setUserData] = React.useState([]);

  const [showDateRange, setShowDateRange] = React.useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(moment().add(-7,"days").toDate()),
      endDate:  new Date(),
      key: 'selection',
      // autoFocus: false
    }
  ]);

  const onChangeDateRange = (item:any) => {
    window.console.log('onChangeDateRange ', item);
    setState([item.selection])
    setInitialDate(moment(item.selection.startDate));
    setEndDate(moment(item.selection.endDate));
  }

  const showDateRangeAction = () => {
    setShowDateRange(!showDateRange);
  }
  useEffect(() => {
    if(kpi_data && kpi_data.overallData){
      setOverallData(kpi_data.overallData);
    }
    if(kpi_data && kpi_data.typeData){
      setTypeData(kpi_data.typeData);
    }
    if(kpi_data && kpi_data.userData){
      setUserData(kpi_data.userData);
    }

  },[kpi_data])

  useEffect(() => {
    // getKPIData(initialDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  },[])

  useEffect(() => {
    getKPIData(initialDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  },[initialDate, endDate])

  /*
  TABLE
   */
  const dataGridColumns: GridColDef[]  = [
    { field: 'data', headerName: ' ', flex: 6, cellClassName: classes.rowTitle },
    { field: 'all', headerName: 'All', flex: 2 },
    { field: 'ios', headerName: 'iOS', flex: 2},
    { field: 'android', headerName: 'Android', flex: 2,
      valueFormatter: (params: GridValueFormatterParams) => {
        const value = params.getValue(params.id, 'android');
        if (value === null) {
          return `No data`;
        }
        return value;
      }
    },
  ];

  const dataPercentageGridColumns: GridColDef[]  = [
    { field: 'data', headerName: ' ', flex: 6,  cellClassName: classes.rowTitle,},
    { field: 'all', headerName: 'All', flex: 2,
      renderCell: (params: GridValueFormatterParams) => {
        const value = params.getValue(params.id, 'all');
        const percentage = Number(
            (params.getValue(params.id, 'allPercentage') as number) ,
        ).toFixed(0).toLocaleString();
        return `${value} (${percentage} %)`;
      }
    },
    { field: 'allPercentage', headerName: '%', hide: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        const percentage = Number(
            (params.getValue(params.id, 'allPercentage') as number) ,
        ).toFixed(0).toLocaleString();
        return `${percentage} %`;
      }
    },
    { field: 'ios', headerName: 'iOS', flex: 2,
      renderCell: (params: GridValueFormatterParams) => {
        const value = params.getValue(params.id, 'ios');
        const percentage = Number(
            (params.getValue(params.id, 'iosPercentage') as number) ,
        ).toFixed(0).toLocaleString();
        return `${value} (${percentage} %)`;
      }
    },
    { field: 'iosPercentage', headerName: '%', hide: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        const percentage = Number(
            (params.getValue(params.id, 'iosPercentage') as number) ,
        ).toFixed(0).toLocaleString();
        return `${percentage} %`;
      }},
    { field: 'android', headerName: 'Android', flex: 2,
      renderCell: (params: GridValueFormatterParams) => {
        const value = params.getValue(params.id, 'android');
        const percentage = Number(
            (params.getValue(params.id, 'androidPercentage') as number) ,
        ).toFixed(0).toLocaleString();
        return `${value} (${percentage} %)`;
      }
    },
    { field: 'androidPercentage', headerName: '%', hide: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        const percentage = Number(
            (params.getValue(params.id, 'androidPercentage') as number) ,
        ).toFixed(0).toLocaleString();
        return `${percentage} %`;
      }},
  ];

  const getKPIData = (startDate: any, endDate: any) => {
    dispatch(dataRequestActions.kpiData({ startDate, endDate}));
  }

  const CustomToolbar = (filename: string) => {
    return (
        <GridToolbarContainer>
          <GridToolbarExport csvOptions={{ allColumns: true, fileName: `${filename}` }}/>
        </GridToolbarContainer>
    );
  }

  const OverallDataToolbar = () => CustomToolbar('Overall Data');
  const TypeDataToolbar = () => CustomToolbar('Type Data');
  const UsersDataToolbar = () => CustomToolbar('Users Data');

  return (
      <Fragment>
        <Grid container spacing={1} justify={"center"} className={classes.dateSelection}>
          <Grid item xs={7} style={{alignSelf: 'center'}} >
            <Typography component="h2" variant="h5">
              Select the time period to extract the KPIs:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <div hidden={showDateRange}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container spacing={0} justify={"center"}>
                  <Grid item xs={6} spacing={0}>
                    <KeyboardDatePicker
                        className={classes.inputStyle}
                        disableToolbar
                        variant="inline"
                        readOnly
                        format="DD/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Start date"
                        value={initialDate}
                        onClick={showDateRangeAction}
                        onChange={handleInitialDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDate={endDate}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                        className={classes.inputStyle}
                        disableToolbar
                        readOnly
                        variant="inline"
                        format="DD/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="End Date"
                        value={endDate}
                        onClick={showDateRangeAction}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        minDate={initialDate}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
            <div hidden={!showDateRange} className={classes.dataRangeSection}>
              <DateRangePicker
                  className={classes.rangeDatePicker}
                  months={2}
                  editableDateInputs={true}
                  onChange={onChangeDateRange}
                  moveRangeOnFirstSelection={true}
                  ranges={state}
                  direction={"horizontal"}
                  maxDate={new Date()}
                  showPreview={true}
                  dateDisplayFormat={"dd/LL/yyyy"}
              />
              <Button onClick={showDateRangeAction} className={classes.dataRangeButton}>
                Ok
              </Button>
            </div>
          </Grid>
        </Grid>

        {kpi_data_isLoading && <>
            <div style={{minHeight: 800}}>
                <CircularProgress />
            </div>
        </>
        }
        { kpi_data_error &&
        <>
            <p>Something when wrong, try again or contact the admin!</p>
        </>
        }

        {!kpi_data_isLoading &&  !kpi_data_error && <>
            <Grid container spacing={4} justify={"center"} className={classes.gridContainer}>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h5" className={classes.tableTitle}>
                        Overall data
                    </Typography>
                    <div style={{  width: '100%', flexGrow: 1}}>
                        <DataGrid
                            autoHeight
                            hideFooter={true}
                            disableExtendRowFullWidth={true}
                            columnBuffer={2}
                            columns={dataGridColumns}
                            rows={overallData}
                            components={{
                              Toolbar: OverallDataToolbar
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={4} justify={"center"} className={classes.gridContainer}>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h5" className={classes.tableTitle}>
                        Adding type
                    </Typography>
                    <div style={{  width: '100%', flexGrow: 1}}>
                        <DataGrid
                            autoHeight
                            hideFooter={true}
                            disableExtendRowFullWidth={true}
                            columnBuffer={2}
                            columns={dataPercentageGridColumns}
                            rows={typeData}
                            components={{
                              Toolbar: TypeDataToolbar
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={4} justify={"center"} className={classes.gridContainer}>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h5" className={classes.tableTitle}>
                        Users Data
                    </Typography>
                    <div style={{  width: '100%', flexGrow: 1}}>
                        <DataGrid
                            hideFooter={true}
                            autoHeight
                            disableExtendRowFullWidth={true}
                            columnBuffer={2}
                            columns={dataPercentageGridColumns}
                            rows={userData}
                            components={{
                              Toolbar: UsersDataToolbar
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </>}

      </Fragment>
  )
}
