import { ThemeAction, IThemeState} from './types'

const initialState: IThemeState = {
  sidebarShadow: false,
  sidebarFixed: true,
  sidebarToggleMobile: false,
  headerFixed: true,
  headerShadow: true,
  footerFixed: false,
  footerShadow: false
}

export default function theme(state = initialState, action: ThemeAction):IThemeState {
  switch (action.type) {
    case "@@theme/SET_SIDEBAR_SHADOW":
      return {
        ...state,
        sidebarShadow: action.payload.sidebarShadow
      };
    case "@@theme/SET_SIDEBAR_FIXED":
      return {
        ...state,
        sidebarFixed: action.payload.sidebarFixed
      };
    case "@@theme/SET_SIDEBAR_TOGGLE_MOBILE":
      return {
        ...state,
        sidebarToggleMobile: action.payload.sidebarToggleMobile
      };
      // Header

    case "@@theme/SET_HEADER_FIXED":
      return {
        ...state,
        headerFixed: action.payload.headerFixed
      };
    case "@@theme/SET_HEADER_SHADOW":
      return {
        ...state,
        headerShadow: action.payload.headerShadow
      };
      // Footer

    case "@@theme/SET_FOOTER_FIXED":
      return {
        ...state,
        footerFixed: action.payload.footerFixed
      };
    default:
      return state

  }
};
