import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as invoiceProcessActions from "../../../stores/modules/invoiceProcess/actions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import {makeStyles} from "@material-ui/core/styles";
import {IStoreState} from "../../../stores/createStore";
import * as yup from "yup";
import {CardContent, CardHeader, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import ProcessInvoice from "../ProcessInvoice";
import Card from '@material-ui/core/Card';
import moment from 'moment';


const styles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red'
  },
  card: {
    marginBottom: 20,
  },
  cardHeader: {
    backgroundColor: 'lightgray',
    fontSize: 18,
    fontWeight: 'bold'
  },
  table: {
    minWidth: 650,
  },
}));

type Props = {
  selectedProduct?: any
}

export default function UserNotification (props: Props) {

  const classes = styles();
  const { push_notification_data,
    push_notification_error,
    push_notification_isLoading } = useSelector((state:IStoreState) => state.dataRequest )

  const dispatch = useDispatch();

  /*
  useEffect(() => {
    if(push_notification_data){
      // window.console.log('push_notification_data ', push_notification_data)
    }
  }, [push_notification_data])
  */

  const showNotificationsList = () => {
    return (<Fragment>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Push Id</TableCell>
                <TableCell >Title</TableCell>
                <TableCell >Sent</TableCell>
                <TableCell >Sandbox</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {push_notification_data.data.map((row: any) => {
                if(row.sandbox === 1) {
                  // return
                }
                return (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell >{row.title}</TableCell>
                      <TableCell>{moment(row.sent_date).fromNow()}</TableCell>
                      <TableCell>{row.sandbox === 1? 'True': ''}</TableCell>
                    </TableRow>
                )
              })
              }
            </TableBody>
          </Table>
        </Fragment>
    )
  }

  const showNotificationsEmpty = () => {
    <p>No User HAS Push tokens</p>
  }
  if (push_notification_isLoading) {
    return <CircularProgress />
  }



  if(push_notification_data){
    if(push_notification_data.data.length === 0) {
      return <Fragment/>
    }

    const hasNotifications  = push_notification_data.data.length > 0

    return (
        <Fragment>
          <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title={"Last push notifications sent"}
            />
            <CardContent>
              {hasNotifications && showNotificationsList() }
              {!hasNotifications && showNotificationsEmpty()}
            </CardContent>
          </Card>

        </Fragment>
    )
  }
  else {
    return <CircularProgress />
  }


};
