import { Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import {createAppStore} from './createStore';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

/*
  Store definition class
 */
const sagaMiddleware = createSagaMiddleware();

const middlewares: Middleware[] = [sagaMiddleware]

const store = createAppStore(rootReducer, middlewares)

sagaMiddleware.run(rootSaga)

export { store };
