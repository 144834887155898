import React, { Fragment } from 'react';

import {Container, Grid} from "@material-ui/core";
import logo from "../../../styles/images/logo.png";
import Typography from "@material-ui/core/Typography";
export default function NotFound() {
  return (
      <Fragment>
        <div className="hero-wrapper bg-composed-wrapper  min-vh-100">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
              <Container maxWidth="md" className="pb-5">
                <Grid container spacing={4} direction="column">
                  <Grid item lg={10}
                        className="px-0 mx-auto d-flex align-items-center">
                    <a href={'/'}>
                      <img src={logo} alt="logo" />
                    </a>
                  </Grid>
                  <Grid item lg={10}
                        className="px-0 mx-auto d-flex align-items-center">
                    <Typography component="h1" variant="h5">
                      Page not found
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </div>
      </Fragment>
  );
}
