import { action } from 'typesafe-actions'
import {ILoginRequest, IAccountData, ISignupRequest} from "../../../utilities/Services/UserService";

/*
  Signup Actions Section
 */
export function signup(signupData: ISignupRequest) {
  return action('@@account/SIGNUP',{signupData});
}
export function signupSuccess(accountData: IAccountData) {
  return action('@@account/SIGNUP_SUCCESS', {accountData});
}
export function signupError(error: Error) {
  return action('@@account/SIGNUP_ERROR',{error});
}


/*
  Login Actions Section
 */
export function login(loginData: ILoginRequest) {
  return action('@@account/LOGIN',{loginData});
}
export function loginSuccess(accountData: IAccountData) {
  return action('@@account/LOGIN_SUCCESS', {accountData});
}
export function loginError(error: Error) {
  return action('@@account/LOGIN_ERROR',{error});
}

export function setUserData(accountData: IAccountData) {
  return action('@@account/SET_USER_DATA', {accountData});
}

/*
  Logout Actions Section
 */
export function logout() {
  return action('@@account/LOGOUT',{});
}
export function logoutSuccess() {
  return action('@@account/LOGOUT_SUCCESS', {});
}
export function logoutError(error: Error) {
  return action('@@account/LOGOUT_ERROR',{error});
}


