// src/views/old_components/login/LoginForm.tsx
import * as React from 'react';
import {compose} from 'recompose';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import LoginStyle from "../../../styles/containers/loginStyle";

type Props = {}

const ForgotForm : React.FC<Props> = (props) => {

  const classes = LoginStyle();

  return (
      <form className={classes.form} noValidate>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
        />
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
        >
          Reset password
        </Button>
        <Grid container>
          <Grid item xs>

          </Grid>
          <Grid item>
            <Link href="/login" variant="body2" color={'secondary'}>
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </form>
  );
}

export default compose<Props, any>(
)(ForgotForm)
