import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "font-awesome/css/font-awesome.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/theme';

ReactDOM.render(<App/>, document.getElementById('root') );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

{/*<ThemeProvider theme={theme}>
       CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.
      <CssBaseline />
      <App />
    </ThemeProvider>*/}
