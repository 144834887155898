import {Divider, Grid, IconButton, Tooltip} from '@material-ui/core';
import React, {Fragment, useEffect, useState} from 'react';
import {Carousel} from "react-responsive-carousel";
import missingImage from "../../../styles/images/no-img.jpeg"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {makeStyles} from "@material-ui/core/styles"; // requires a loader
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import UploadService from "../../../utilities/Services/UploadService";
import { getImageURL } from '../../../utilities/FilesUtilitie';
import {Button} from "react-bootstrap";
import {AddToPhotos, Description, OndemandVideo} from "@material-ui/icons";
import _ from "lodash";

type Props = {
  productObject: any;
  addProductFromInvoice? : () => void;
  addProductData? : () => void;
  addProductVideo?: () => void;
  isViewMode?: boolean;
}


const styles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  rowsText: {
    fontSize: 18,
  },
  rowStyle: {
    fontSize: 16,
    padding: 10,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  productTitle:{
    textAlign:"center",
    fontSize: 22,
    fontWeight: "bolder"
  },
  deleted: {
    color: "red",
    fontWeight: "bolder",
    textAlign:"center",
  },
  imageZoomContainer: {
    textAlign: 'right',
    paddingRight:36
  },
  hideZoomContainer:{
    display: "none"
  },
  imageZoom: {
    top: 0,
    right: 36,
    fontSize: 20,
    textShadow: "1px 1px 1px rgb(0 0 0 / 90%)",
    color: "black",
  },
  noPhoto: {
    height: "100%",
    textAlign:"center",
    justifyItems: "center",
    padding: "33% 0",
    fontWeight: "bolder",
    backgroundColor: "#f8f9ff"
  },
  actionHeader: {
    fontWeight: "bolder",
    fontSize: 16,
    paddingTop:20
  }

}));


const ProductInfo = (props: Props) => {
  const {
    productObject
  } = props

  const classes = styles();

  const [photosObjs, setPhotoObjs] = useState<any>();

  const imagesUrl = process.env.REACT_APP_IMAGES_BASE_URL
  // https://stage-www.zenown.com/sell/images
  useEffect(() => {
    if (productObject.Photos){
      productObject.Photos.forEach(async (photo: any) => {
        photo.thumbImg = `${imagesUrl}/thumbnail/${photo.uuid}.jpg`;
        photo.originalImg = `${imagesUrl}/original/${photo.uuid}.jpg`;
      })
      setPhotoObjs(productObject.Photos);
    }
    else if(!productObject.Photos && productObject.photos) {
      productObject.Photos = []
      const photosList = productObject.photos.split(' ');
      photosList.forEach(async (photo: any) => {
        photo.thumbImg = `${imagesUrl}/thumbnail/${photo}.jpg`;
        photo.originalImg = `${imagesUrl}/original/${photo}.jpg`;
        productObject.Photos.push(photo);
      })
      setPhotoObjs(productObject.Photos);
    }
  }, []);

  const renderPhotos = (photosObject: any) => {
    if(photosObjs){
      return photosObjs.map( (photo: any) => {
        const addMissingImage = (evt: any) => {
          evt.target.src = missingImage
          hideZoomLink = true
        }

        let hideZoomLink = false
        let photoType = ''
        switch (photo.type) {
          case 0:
            photoType = 'Product';
            break;
          case 1:
            photoType = 'Invoice';
            break;
          case 2:
            photoType = 'Warranty';
            break;
        }

        return(
            <div>
              <div className={hideZoomLink? classes.hideZoomContainer: classes.imageZoomContainer }>
                <a href={photo.originalImg}
                   target={'_blank'}>
                  <ImageSearchIcon className={classes.imageZoom} />
                </a>
              </div>

              <img
                  onError={addMissingImage}
                  src={photo.thumbImg}/>
              {photoType && <p className="legend">{photoType}</p> }
            </div>
        )
      })
    }
    else {
      return(
          <div>
            <p>
              NO PHOTOS FOR THIS PRODUCT
            </p>
          </div>
      )
    }
  }

  const onChange = () => {}

  const onClickItem = (item: any) => {
    // window.console.log('onClickItem ', item)
  }
  const onClickThumb = () => { }

  const renderProductInfo = (product: any) => {
    return(
        <Grid container={true}>
          <Grid item={true} xs={12}>
            { renderProductInfoDetail('Category', product.category)}
            { renderProductInfoDetail('Product nickname', product.nickname)}
            { renderProductInfoDetail('Product brand', product.brand)}
            { renderProductInfoDetail('Purchase date', product.purchaseDate !== '' ? product.purchaseDate : '' )}
            { renderProductInfoDetail('Product price', product.price + '' +product.currency)}
            { renderProductInfoDetail('Purchase location', product.purchaseLocation)}
            { renderProductInfoDetail('Warranty date', product.warrantyDate !== '' ? product.warrantyDate : '' )}
          </Grid>
        </Grid>
    )
  }

  const renderProductInfoDetail = (name: string, value: string) => {
    return (
        <Grid container={true} className={classes.rowStyle}>
          <Grid item={true} xs={12} sm={6}>
            <b>{name}:</b>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            {value}
          </Grid>
        </Grid>
    )
  }



  if(!productObject) {
    return (<></>)
  }

  /*
 params.getValue('addType') === 'invoiceScan' &&
           params.getValue('invoiceProcessing') !== 1 &&
           params.getValue('invoiceProcessing') !== -1
  */

  const showAddProductFromInvoice = // productObject.addType === 'invoiceScan'&&
      productObject.invoiceProcessing !== 1 &&
      productObject.invoiceProcessing !== -1;

  const showAddProductData = !productObject.name;
  const showAddProductVideo = productObject.deleted === 0 && productObject.invoiceProcessing !== 1  &&
      productObject.invoiceProcessing !== -1;

  return (<>
    <Fragment>
      <Grid container={true} spacing={5}>
        <Grid item={true} xs={12} sm={6}>
          {productObject.Photos && productObject.Photos.length > 0 &&
          <Carousel  showArrows={true}
                     onChange={onChange}
                     onClickItem={onClickItem}
                     onClickThumb={onClickThumb}>
            {
              renderPhotos(productObject.Photos)
            }
          </Carousel>
          }
          {!productObject.Photos && productObject.Photos.length === 0 &&
          <div className={classes.noPhoto}>
              <p className="legend">
                  NO PHOTOS FOR THIS PRODUCT
              </p>
          </div>
          }
        </Grid>
        <Grid item={true} xs={12} sm={6} justify={"center"}>
          <p className={classes.productTitle}>{productObject.name}</p>
          {productObject.deleted === 1 &&
          <p className={classes.deleted}>Deleted</p>}
          {renderProductInfo(productObject)}

          {!props.isViewMode && <>
            {(showAddProductFromInvoice || showAddProductData || showAddProductVideo) && <>
                <div style={{paddingTop: 20}}>
                    <Divider variant="middle" />
                    <span className={classes.actionHeader}>Actions</span>
                </div>
            </>}
            {showAddProductFromInvoice &&  <Tooltip title="Add another product from invoice" >
                <IconButton color="primary"
                            onClick={props.addProductFromInvoice}
                            aria-label="Add another product from invoice" component="span">
                    <AddToPhotos />
                </IconButton>
            </Tooltip>
            }

            {
              showAddProductData && <Tooltip title="Add product data from invoice">
                  <IconButton color="primary"
                              onClick={props.addProductData}
                              aria-label="Add product data from invoice" component="span">
                      <Description />
                  </IconButton>
              </Tooltip>
            }

            {showAddProductVideo && <Tooltip title="Add Video">
                <IconButton color="primary"
                            onClick={props.addProductVideo}
                            aria-label="Add Video" component="span">
                    <OndemandVideo/>
                </IconButton>
            </Tooltip>}
          </>
          }

        </Grid>

      </Grid>
    </Fragment>
  </>)
}

export default ProductInfo
