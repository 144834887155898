import { applyMiddleware, createStore, Middleware, Reducer } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension'
import {AccountAction, IAccountState} from "./modules/account/types";
import {ThemeAction, IThemeState} from "./modules/theme/types";
import {IDataRequestState} from "./modules/dataRequest/types";
import {IInvoiceRequestState} from "./modules/invoiceProcess/types";

export interface IStoreState {
  dataRequest: IDataRequestState;
  account: IAccountState;
  theme: IThemeState;
  invoiceRequest: IInvoiceRequestState;
}

export type StoreAction = AccountAction;

export const createAppStore = (reducers: Reducer<IStoreState, StoreAction>, middlewares: Middleware[])  => {
  const enhancer = applyMiddleware(...middlewares);
  return createStore(reducers, composeWithDevTools(
      enhancer,
  ));
}
