import * as _ from "lodash";
import moment from 'moment';

export const handleTotalNewUsersInLastNDays = (newUsersData: any, getLastNDays: number) => {
  const totalUsers = newUsersData.userCount;
  const dayTotalUser:any = [];
  const days:any = [];
  let dayIndex = 0;

  const lastNDays = newUsersData.data.length > getLastNDays ? _.take(newUsersData.data, getLastNDays) : newUsersData.data

  while (dayIndex < getLastNDays) {
    const labelDay = moment().add(-dayIndex, "days").format('DD MMM');
    dayTotalUser.push(0);
    days.push(labelDay)
    dayIndex++
  }

  lastNDays.forEach((dayData: any) => {
    const labelDateFormat = moment(dayData.d).format('DD MMM');
    const index = _.indexOf(days, labelDateFormat);
    dayTotalUser[index] += dayData.user_count
  })

  return {
    total: totalUsers,
    label: days.reverse(),
    data: dayTotalUser.reverse()
  }
}

export const handleTotalNewUsers = (newUsersData: any) => {
  const totalUsers = newUsersData.userCount;
  const dayTotalUser:any = [];
  const days:any = [];
  let dayIndex = 0;

  const lastNDays = newUsersData.data.length > 365 ? _.take(newUsersData.data, 365) : newUsersData.data

  while (dayIndex < 365) {
    const labelDay = moment().add(-dayIndex, "days");
    dayTotalUser.push({ milliseconds: labelDay.valueOf(), label:labelDay.format('DD MMM'), count:  0});
    days.push(labelDay.format('DD MMM YYYY'))
    dayIndex++
  }

  lastNDays.forEach((dayData: any) => {
    const labelDateFormat = moment(dayData.d);
    const user_count = dayData.user_count

    const index = _.indexOf(days, labelDateFormat.format('DD MMM YYYY'));

    dayTotalUser[index] = {
      milliseconds:labelDateFormat.valueOf(),
      label:labelDateFormat.format('DD MMM YYYY'),
      count:dayData.user_count
    }
  })

  const dataObj :any = [];
  const labels :any = [];

  dayTotalUser.reverse().forEach((dayData:any) => {
    dataObj.push([dayData.milliseconds, dayData.count]);
    // dataObj.push(dayData.count);
    labels.push(dayData.label)
  })

  return {
    total: totalUsers,
    label: labels,
    data: dataObj
  }
}

export const handleTotalUsersByWeek = (newUsersData: any, getLastNWeeks: number) => {
  let totalUsers = newUsersData.userCount;
  const userWeeklyTotals:any  = [totalUsers];
  const weeksBack = ['now'];
  let d = 0;
  let weeksTotal = 0

  newUsersData.data.forEach((dayData: any) => {
    if(weeksTotal < getLastNWeeks){
      totalUsers -= dayData.user_count;
      if (d >= 7) {
        d = 0
        userWeeklyTotals.push(totalUsers);
        weeksBack.push((userWeeklyTotals.length - 1)+'w ago')
        weeksTotal++;
      } else {
        d++;
      }
    }
  })

  while(weeksBack.length <= getLastNWeeks){
    weeksBack.push((weeksBack.length )+'w ago');
    userWeeklyTotals.push(0);
  }

  return {
    total: totalUsers,
    label: weeksBack.reverse(),
    data: userWeeklyTotals.reverse()
  }
}

export const handleGlobalStatisticData = (globalStatistic: any) => {

  const labels :any = [];
  const values :any = [];
  const totals :any = [];

  if(globalStatistic.brandTotals) {
    labels.push('With Brands');
    values.push(((globalStatistic.brandTotals/globalStatistic.totalProducts) * 100).toFixed(1));
    totals.push(globalStatistic.brandTotals);
  }

  if(globalStatistic.hasInvoice) {
    labels.push('With Invoices');
    values.push(((globalStatistic.hasInvoice/globalStatistic.totalProducts) * 100).toFixed(1));
    totals.push(globalStatistic.hasInvoice);
  }

  if(globalStatistic.nicknameTotals) {
    labels.push('With Nicknames');
    values.push(((globalStatistic.nicknameTotals/globalStatistic.totalProducts) * 100).toFixed(1));
    totals.push(globalStatistic.nicknameTotals);
  }

  if(globalStatistic.purchaseSameDateTotals) {
    labels.push('Purchased same day');
    values.push(((globalStatistic.purchaseSameDateTotals/globalStatistic.totalProducts) * 100).toFixed(1));
    totals.push(globalStatistic.purchaseSameDateTotals);
  }

  if(globalStatistic.hasPurchaseDate) {
    labels.push('Has Purchased date');
    values.push(((globalStatistic.hasPurchaseDate/globalStatistic.totalProducts) * 100).toFixed(1));
    totals.push(globalStatistic.hasPurchaseDate);
  }

  if(globalStatistic.warrantyTotals) {
    labels.push('With Warranty');
    values.push(((globalStatistic.warrantyTotals/globalStatistic.totalProducts) * 100).toFixed(1));
    totals.push(globalStatistic.warrantyTotals);
  }

  if(globalStatistic.warrantyTotals) {
    labels.push('With No Warranty' );
    values.push((  100 - (globalStatistic.warrantyTotals/globalStatistic.totalProducts) * 100).toFixed(1));
    totals.push(globalStatistic.totalProducts-globalStatistic.warrantyTotals);
  }

  return {
    labels,
    values,
    totals
  }
}

export const handleProductPhotosStatistic = (productsCount: any, productPhotoData: any) => {

  const totals = productPhotoData.data.map((product:any) => product.total);

  const meanValue = _.mean(totals);

  let seriesData = _.countBy(totals);

  seriesData = { 0: productsCount - productPhotoData.total, ...seriesData}

  return {
    meanValue,
    seriesData
  }
}

export const handleUserWithProductsPie = (userProductsData: any) => {

  const userWithProducts = _.countBy(userProductsData.data, (ele:any) => {return ele.total > 0})

  return { 'With Product': userWithProducts.true,'Without Product': userWithProducts.false}
}

export const handleProductByCategory = (productByCategoryData: any) => {

  const labels :any = [];
  const values :any = [];

  productByCategoryData.data.forEach((categoryData:any) => {
    if (categoryData.category=== null) {
      labels.push('No Category')
      values.push(categoryData.total)
    }
    else {
      labels.push(categoryData.category)
      values.push(categoryData.total)
    }
  })
  return {
    labels,
    values
  }
}
