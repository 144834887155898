import React, { Fragment } from 'react';
import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';

import {connect, useDispatch, useSelector} from 'react-redux';
import * as themeActions from "../../../stores/modules/theme/actions";

import SidebarHeader from '../../components/SidebarHeader';
import SidebarMenu from '../../components/SidebarMenu';

import navItems from './navItems';
import {IStoreState} from "../../../stores/createStore";

/*
The sidebar layout used in the LeftSideBar page container
 */

const Sidebar = (props:any) => {

  const {
    sidebarFixed,
    sidebarShadow,
    sidebarToggleMobile
  } = useSelector((state: IStoreState) => state.theme);

  const dispatch = useDispatch();

  const closeDrawer = () => dispatch(themeActions.setSidebarToggleMobile(!sidebarToggleMobile));

  const sidebarMenuContent = (
    <div>
      {navItems.map(list => (
        <SidebarMenu
          component="div"
          key={list.label}
          pages={list.content}
          title={list.label}
        />
      ))}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

export default Sidebar;
