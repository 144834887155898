import { action } from 'typesafe-actions'

// Sidebar
export function setSidebarShadow (sidebarShadow:boolean) {
  return action('@@theme/SET_SIDEBAR_SHADOW', {sidebarShadow})
};

export function setSidebarFixed (sidebarFixed:boolean) {
  return action('@@theme/SET_SIDEBAR_FIXED', {sidebarFixed})
};

export function setSidebarToggleMobile (sidebarToggleMobile:boolean) {
  return action('@@theme/SET_SIDEBAR_TOGGLE_MOBILE', {sidebarToggleMobile})
};

// Header
export function setHeaderFixed (headerFixed:boolean) {
  return action('@@theme/SET_HEADER_FIXED', {headerFixed})
};
export function setHeaderShadow (headerShadow:boolean) {
  return action('@@theme/SET_HEADER_SHADOW', {headerShadow})
};
export function setHeaderSearchHover (headerSearchHover:boolean) {
  return action('@@theme/SET_HEADER_SEARCH_HOVER', {headerSearchHover})
};

// Footer
export function setFooterFixed (footerFixed:boolean) {
  return action('@@theme/SET_FOOTER_FIXED', {footerFixed})
};
