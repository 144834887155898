// src/utilities/Services/AuthenticationServices.ts
import { v1 as uuidv1 } from 'uuid';
import {useDispatch} from "react-redux";
import * as accountActions from "../../stores/modules/account/actions";
import account from "../../stores/modules/account/reducer";
import {IAccountData} from "./UserService";

const BEARER_TOKEN_NAME = "bearer_token";
const BEARER_TOKEN_EXPIRES_AT = "bearer_expires_at";

const EXPIRATION_DURATION = 3600 * 24 * 365 * 1000 // 1 year

const SESSION_TOKEN = "session_token"
const SESSION_TOKEN_EXPIRES_AT = "session_expires_at"
const SESSION_TOKEN_EXPIRATION_DURATION = 3600 * 1000 // 1 hour

const USER_NAME = "user_name";
const USER_EMAIL = "user_email";

export default class AuthenticationService {

  public authenticate = (authenticationData: any) => {
    localStorage.setItem(BEARER_TOKEN_NAME, authenticationData.token);
    localStorage.setItem(BEARER_TOKEN_EXPIRES_AT, `${Date.now() + EXPIRATION_DURATION}`);

    localStorage.setItem(USER_NAME, authenticationData.userName);
    localStorage.setItem(USER_EMAIL, authenticationData.userEmail);

    this.generateSessionToken()

    return authenticationData.token
  }

  public isAuthenticated = () => {
    const bearerToken = this.getCookie(BEARER_TOKEN_NAME);
    const bearerExpiresAt = this.getCookie(BEARER_TOKEN_EXPIRES_AT);

    if(!this.asValidSessionToken()){
      this.generateSessionToken()
    }

    return (!!(bearerToken && bearerExpiresAt && parseInt(bearerExpiresAt, 10) > Date.now()))
  }

  public deAuthenticate = () => {
    this.deleteCookie(BEARER_TOKEN_NAME);
    this.deleteCookie(BEARER_TOKEN_EXPIRES_AT);

    this.generateSessionToken()
    window.location.href = '/';
    return;
  }

  public getBearerToken = () => {
    return this.getCookie(BEARER_TOKEN_NAME);
  }

  public getBearerTokenExpirationDate = () => {
    return this.getCookie(BEARER_TOKEN_EXPIRES_AT);
  }

  public asValidSessionToken = () => {
    const sessionToken = this.getCookie(SESSION_TOKEN);
    const sessionrExpiresAt = this.getCookie(SESSION_TOKEN_EXPIRES_AT);
    return (!!(sessionToken && sessionrExpiresAt && parseInt(sessionrExpiresAt, 10) > Date.now()));
  }

  public getSessionToken = () => {
    return this.getCookie(SESSION_TOKEN);
  }

  public getSessionTokenExpirationDate = () => {
    return this.getCookie(SESSION_TOKEN_EXPIRES_AT);
  }

  public getAccountData = () => {
    const userName = this.getCookie(USER_NAME);
    const userEmail = this.getCookie(USER_EMAIL);

    return {userName, userEmail}
  }
  private  getCookie = (cname: string) => {
    const val = localStorage.getItem(cname);
    if (val) {
      return val
    }
    return "";
  }

  private deleteCookie = (name: string) => {
    localStorage.removeItem(name);
  }

  private generateSessionToken = () => {
    const generatedSession = uuidv1();

    localStorage.setItem(SESSION_TOKEN, generatedSession);
    localStorage.setItem(SESSION_TOKEN_EXPIRES_AT, `${Date.now() + SESSION_TOKEN_EXPIRATION_DURATION}`);
  }

}
