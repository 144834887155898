import React, {Fragment, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";
import AddProductVideo from "../AddProductVideo";
import UserNotification from "../UserNotification";
import AddYoutubeVideo from "../AddYoutubeVideo";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";


const styles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red'
  }

}));

type Props = {
  selectedProduct?: any
}

export default function AddProductVideoSection (props: Props) {

  const classes = styles();


  const dispatch = useDispatch();

  useEffect(() => {
    if(props.selectedProduct) {
      dispatch(dataRequestActions.getPushNotifications(props.selectedProduct.userID));
      dispatch(dataRequestActions.getProductVideos(props.selectedProduct.id));
    }
  }, [])

  if(props.selectedProduct){
    return (
        <Fragment>
          <AddYoutubeVideo selectedProduct={props.selectedProduct} />
          <AddProductVideo selectedProduct={props.selectedProduct} />
          <UserNotification selectedProduct={props.selectedProduct} />
        </Fragment>
    )
  }
  else {
    return <CircularProgress />
  }


};
