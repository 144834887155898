import React, {Fragment, useEffect} from 'react';
import { Grid } from '@material-ui/core';

import { WrapperSimple, PageTitle } from '../../components';
import UsersList from "../../tables/UsersList";
import {useDispatch} from "react-redux";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";
import UsersProductPie from '../../charts/UsersProductPie';

/*
  Users page
 */

export default function Users() {
  const dispatch = useDispatch();

  // Getting the data from the API
  useEffect(() => {
    dispatch(dataRequestActions.getProductsUsersCount())
  }, [])

  return (
      <Fragment>
        <PageTitle
            titleHeading="Users"
            titleDescription="ZenOwn users"
        />
        <Grid container spacing={4} justify={"center"}>
          <Grid item xs={8}>
            <WrapperSimple sectionHeading="Products per user">
              <UsersProductPie />
            </WrapperSimple>
          </Grid>
          <Grid item xs={12}  >
            <WrapperSimple sectionHeading="Products per user">
              <UsersList />
            </WrapperSimple>
          </Grid>
        </Grid>
      </Fragment>
  );
}
