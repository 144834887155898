import { action } from 'typesafe-actions'

/* Invoice find user */
export function findUserInvoiceForwarding(userEmail: any) {
  return action('@@dataRequest/FIND_USER_INVOICE_FORWARDING', {userEmail});
}
export function findUserInvoiceForwardingSuccess(dataRequested: any) {
  return action('@@dataRequest/FIND_USER_INVOICE_FORWARDING_SUCCESS', {dataRequested});
}
export function findUserInvoiceForwardingError(error: Error) {
  return action('@@dataRequest/FIND_USER_INVOICE_FORWARDING_ERROR',{error});
}
export function findUserInvoiceForwardingClear() {
  return action('@@dataRequest/FIND_USER_INVOICE_FORWARDING_CLEAR');
}


/* Process user invoice */
export function processUserInvoice(data: any) {
  return action('@@dataRequest/PROCESS_USER_INVOICE', {data});
}
export function processUserInvoiceSuccess(dataRequested: any) {
  return action('@@dataRequest/PROCESS_USER_INVOICE_SUCCESS', {dataRequested});
}
export function processUserInvoiceError(error: Error) {
  return action('@@dataRequest/PROCESS_USER_INVOICE_ERROR',{error});
}
export function processUserInvoiceClear() {
  return action('@@dataRequest/PROCESS_USER_INVOICE_CLEAR');
}

export function processAddProductData(data: any) {
  return action('@@dataRequest/PROCESS_ADD_PRODUCT_DATA', {data});
}
export function processAddProductDataSuccess(dataRequested: any) {
  return action('@@dataRequest/PROCESS_ADD_PRODUCT_DATA_SUCCESS', {dataRequested});
}
export function processAddProductDataError(error: Error) {
  return action('@@dataRequest/PROCESS_ADD_PRODUCT_DATA_ERROR',{error});
}


export function finishingProcessInvoice(data: any) {
  return action('@@dataRequest/FINISHING_PROCESS_INVOICE', {data});
}
export function finishingProcessInvoiceSuccess(dataRequested: any) {
  return action('@@dataRequest/FINISHING_PROCESS_INVOICE_SUCCESS', {dataRequested});
}
export function finishingProcessInvoiceError(error: Error) {
  return action('@@dataRequest/FINISHING_PROCESS_INVOICE_ERROR', {error});
}
