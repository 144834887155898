import { Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import {Carousel} from "react-responsive-carousel";
import missingImage from "../../../styles/images/no-img.jpeg"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {makeStyles} from "@material-ui/core/styles"; // requires a loader

type Props = {
  sellObject: any
}


const styles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  rowsText: {
    fontSize: 18,
  },
  rowStyle: {
    fontSize: 16,
    padding: 10,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  productTitle:{
    textAlign:"center",
    fontSize: 22,
    fontWeight: "bolder"
  },
  deleted: {
    color: "red",
    fontWeight: "bolder",
    textAlign:"center",
  }
}));


const SellInfo = (props: Props) => {
  const {
    sellObject
  } = props

  const classes = styles();

  const addMissingImage = (evt: any) => {
    evt.target.src = missingImage
  }

  const renderPhotos = (photosObject: any) => {
    if(photosObject){
      return photosObject.map((photo: any) => {

        let photoType = ''
        switch (photo.type) {
          case 0:
            photoType = 'Invoice';
            break;
          case 1:
            photoType = '';
            break;
          case 2:
            photoType = 'Warranty';
            break;
        }

        return(
            <div>
              <img
                  onError={addMissingImage}
                  src={'https://stage-www.zenown.com/sell/images/sellDetail2/'+photo.uuid+'.jpg'}/>
              {photoType && <p className="legend">{photoType}</p> }
            </div>
        )
      })
    }
  }

  const onChange = () => {}
  const onClickItem = () => { }
  const onClickThumb = () => { }

  const renderSellInfo = (sell: any) => {
    const product = sell.Product && sell.Product.name ? sell.Product.name : '';
    return(
        <Grid container={true}>
          <Grid item={true} xs={12}>
            { renderSellInfoDetail('Id', sell.id)}
            { renderSellInfoDetail('Title', sell.title)}
            { renderSellInfoDetail('Description', sell.description)}
            { renderSellInfoDetail('Product', product)}
            { renderSellInfoDetail('Price', sell.price + '' +sell.currency)}
            { renderSellInfoDetail('Date', sell.date)}
          </Grid>
        </Grid>
    )
  }

  const renderSellInfoDetail = (name: string, value: string) => {
    return (
        <Grid container={true} className={classes.rowStyle}>
          <Grid item={true} xs={12} sm={6}>
            <b>{name}:</b>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            {value}
          </Grid>
        </Grid>
    )
  }

  if(!sellObject) {
    return (<></>)
  }
  return (<>
    <Fragment>
      <Grid container={true} spacing={5}>
        <Grid item={true} xs={12} sm={6}>
          <Carousel  showArrows={true}
                     onChange={onChange}
                     onClickItem={onClickItem} onClickThumb={onClickThumb}>
            {
              renderPhotos(sellObject.Photos)
            }
          </Carousel>

        </Grid>
        <Grid item={true} xs={12} sm={6} justify={"center"}>
          <p className={classes.productTitle}>{sellObject.title}</p>
          {sellObject.deleted === 1 &&
          <p className={classes.deleted}>Deleted</p>}
          {renderSellInfo(sellObject)}
        </Grid>
      </Grid>
    </Fragment>
  </>)
}

export default SellInfo
