import { ActionType } from 'typesafe-actions';
import { takeLatest, all,call,put } from 'redux-saga/effects'
import * as actions from './actions'
import DataService from "../../../utilities/Services/DataService";


export function* findUserInvoiceForwarding ({payload}: ActionType<typeof actions.findUserInvoiceForwarding>): any {
  try {
    const dataService = new DataService();
    const userInvoiceData = yield call(dataService.getUserInvoiceForward, payload.userEmail);
    yield put(actions.findUserInvoiceForwardingSuccess(userInvoiceData));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.findUserInvoiceForwardingError(error));
  }
}


export function* processUserInvoice ({payload}: ActionType<typeof actions.processUserInvoice>): any {
  try {
    const dataService = new DataService();
    const userInvoiceData = yield call(dataService.processUserInvoice, payload.data);

    if(!userInvoiceData.data.Photos && userInvoiceData.data.photos) {
      userInvoiceData.data.Photos = userInvoiceData.data.photos
          .split(' ')
          .map((photo:any)=> {return {uuid: photo} } );
    }

    yield put(actions.processUserInvoiceSuccess(userInvoiceData));
  }
  catch (error: any) {
    // TODO:Implement error call
    window.console.log('error ', error);
    yield put(actions.processUserInvoiceError(error));
  }
}

export function* processAddProductData({payload}: ActionType<typeof actions.processUserInvoice>): any {
  try {
    const dataService = new DataService();
    const addProductData = yield call(dataService.processAddProductData, payload.data);

    if(!addProductData.data.Photos && addProductData.data.photos) {
      addProductData.data.Photos = addProductData.data.photos
          .split(' ')
          .map((photo:any)=> {return {uuid: photo} } );
    }
    yield put(actions.processAddProductDataSuccess(addProductData));
  }
  catch (error: any) {
    // TODO:Implement error call
    window.console.log('error ', error);
    yield put(actions.processAddProductDataError(error));
  }
}

export function* finishingProcessing({payload}: ActionType<typeof actions.processUserInvoice>): any {
  try {
    const dataService = new DataService();
    payload.data.processingType = 'processInvoice';
    const finishProductProcessing = yield call(dataService.finish_process, payload.data);

    if(!finishProductProcessing.data.Photos && finishProductProcessing.data.photos) {
      finishProductProcessing.data.Photos = finishProductProcessing.data.photos
          .split(' ')
          .map((photo:any)=> {return {uuid: photo} } );
    }
    yield put(actions.processAddProductDataSuccess(finishProductProcessing));
  }
  catch (error: any) {
    // TODO:Implement error call
    window.console.log('error ', error);
    yield put(actions.processAddProductDataError(error));
  }
}
export default all([
  takeLatest('@@dataRequest/FIND_USER_INVOICE_FORWARDING',findUserInvoiceForwarding),
  takeLatest('@@dataRequest/PROCESS_USER_INVOICE',processUserInvoice),
  takeLatest('@@dataRequest/PROCESS_ADD_PRODUCT_DATA',processAddProductData),
  takeLatest('@@dataRequest/FINISHING_PROCESS_INVOICE',finishingProcessing),

])
