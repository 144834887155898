import { AccountAction, IAccountState } from './types'

const initialState: IAccountState = {
  isLoading: false,
  isLogged: false,
}

export default function account(state = initialState, action: AccountAction):IAccountState {
  switch (action.type) {
    case "@@account/LOGIN":
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case "@@account/LOGIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isLogged: true,
        error: undefined,
        account: action.payload.accountData
      };
    case "@@account/LOGIN_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case "@@account/SIGNUP":
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case "@@account/SIGNUP_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isLogged: false,
        error: undefined,
        accountRegister: action.payload.accountData
      };
    case "@@account/SIGNUP_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case "@@account/LOGOUT":
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case "@@account/LOGOUT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isLogged: true,
        error: undefined,
        account: undefined
      };
    case "@@account/LOGOUT_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case "@@account/SET_USER_DATA":
      return {
        ...state,
        account: action.payload.accountData
      };
    default:
      return state

  }

}
