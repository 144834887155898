import React, {Fragment, useEffect, useState} from 'react';

import Chart from 'react-apexcharts';
import { handleTotalUsersByWeek} from '../../../utilities/Charts/ChartsService';
import {useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import {CircularProgress} from "@material-ui/core";

type Props = {}

export default function UserPerWeek(props: Props) {

  const {users_data, users_android_data, users_data_isLoading, users_android_isLoading,
    users_android_error, users_data_error  } = useSelector((state:IStoreState) => state.dataRequest )

  const [chartData, setChartData] = useState<any>();
  const [charAndroidData, setChartAndroidData] = useState<any>();

  const [options, setOptions] = useState<any>();
  const [series, setSeries] = useState<any>();

  useEffect(() => {
    if(users_data && users_android_data) {
      setChartData(handleTotalUsersByWeek(users_data, 6));
      setChartAndroidData(handleTotalUsersByWeek(users_android_data, 6));
    }
  }, [users_data, users_android_data])

  useEffect(() => {
    if(chartData) {
      setOptions({
        stroke: {
          curve: 'smooth'
        },
        markers: {
          size: 0
        },
        xaxis: {
          categories: chartData.label
        },
        chart:{
          toolbar:{
            show: false
          }
        }
      });
      setSeries([
        {
          name:'Users by week',
          data: chartData.data
        },
        {
          name:'Andoird users by week',
          data: charAndroidData.data
        }
      ]);
    }

  }, [chartData, charAndroidData])


  if (users_data_isLoading || users_android_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(users_android_error || users_data_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  if(!series ) {
    return <></>
  }

  return (
      <Fragment>
        <Chart options={options} series={series} type="bar" />
      </Fragment>
  );
}
