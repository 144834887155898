import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Paper, List, ListItem, ListItemText } from '@material-ui/core';

import {connect, useSelector} from 'react-redux';
import {IStoreState} from "../../../stores/createStore";

const Footer = (props: any) => {
  const {
    footerFixed
  } = useSelector((state: IStoreState) => state.theme);

  return (
    <Fragment>
      <Paper
        square
        className={clsx('app-footer text-black-50', {
          'app-footer--fixed': footerFixed
        })}>
        <div className="app-footer--inner">
          <div className="app-footer--first" />

          <div className="app-footer--second">
            <span>ZenOwn</span> © 2021
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

export default Footer;
