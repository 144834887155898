import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from "react-redux";

import {compose} from "recompose";
import {store} from "./stores";
import CssBaseline from '@material-ui/core/CssBaseline';
import ScrollToTop from './utilities/ScrollToTop';
import Routes from "./routes";

import './assets/base.scss';

type Props = {}

const App: React.FC<Props>  =  (props) => {

  return (
      <Provider store={store}>
        <BrowserRouter basename="/">
          <CssBaseline />
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
  );
}
export default compose<Props,any>(
)(App);
