import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import _ from "lodash";

import {
  CircularProgress,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination, Grid, Button
} from '@material-ui/core';
import { TableFooter } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import TablePaginationActions from '../PaginationActions';
import SearchBar from "material-ui-search-bar";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";
import {UserProductsCountTable, UserProductsTable} from "../index";

const styles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  rowStyle: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  closeInfo: {
    textAlign: 'right',
    height: 48
  },
  userInfoHeader:{
    height: 48 ,
    textAlign: 'center',
    fontWeight: 'bolder'
  }
}));

type Props = {}

export default function UsersList () {
  const {
    user_products_list_data,
  } = useSelector((state:IStoreState) => state.dataRequest )

  const dispatch = useDispatch();
  const showUserInfo = user_products_list_data ? true: false


  return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={showUserInfo? 3: 12} >
            <UserProductsCountTable />
          </Grid>
          <Grid item hidden={!showUserInfo} xs={12} md={9}>
            <UserProductsTable />
          </Grid>
        </Grid>
      </Fragment>
  )
}
