import { combineReducers } from 'redux';
import { IStoreState } from '../createStore';
import account from "./account/reducer";
import theme  from "./theme/reducer";
import dataRequest  from "./dataRequest/reducer";
import invoiceRequest  from "./invoiceProcess/reducer";

export default combineReducers<IStoreState>({
  account,
  theme,
  dataRequest,
  invoiceRequest
})
