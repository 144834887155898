import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Sidebar, Header, Footer } from '../../components';
import {IStoreState} from "../../../stores/createStore";
import {compose} from "recompose";

type Props = {};

/*
Left Sidebar is the container of all the admin pages
having the list on the left tab and showing the contents on the main container
 */

const LeftSidebar: React.FC<Props>  = (props:any) => {

  const {
    sidebarFixed,
    footerFixed,
    sidebarToggleMobile
  } = useSelector((state: IStoreState) => state.theme);

  const {
    children,
    contentBackground,
  } = props;

  return (
    <Fragment>
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header />
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed
          })}>
          <Sidebar />
          <div
            className={clsx('app-content', {
              'app-content-sidebar-collapsed': sidebarToggleMobile,
              'app-content-sidebar-fixed': sidebarFixed,
              'app-content-footer-fixed': footerFixed
            })}>
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

export default compose<Props,any>(
)(LeftSidebar);
