import React, {Fragment, Suspense, useEffect, useRef, useState} from 'react';

import {handleTotalNewUsers } from '../../../utilities/Charts/ChartsService';
import { useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import {CircularProgress} from "@material-ui/core";
// import ApexCharts from 'apexcharts';
import moment from 'moment';
import Chart from "react-apexcharts";
import { Button } from '@material-ui/core';

export default function GlobalUsersPerDay() {

  const { users_data, users_android_data, users_data_isLoading, users_android_isLoading,
    users_android_error, users_data_error } = useSelector((state:IStoreState) => state.dataRequest )

  const [chartData, setChartData] = useState<any>();
  const [charAndroidData, setChartAndroidData] = useState<any>();

  const [options, setOptions] = useState<any>();
  const [series, setSeries] = useState<any>();
  const [selection, setSelection] = useState<string>();
  const [limit, setLimit] = useState<number>();

  const chartRef = useRef<any>(null);


  useEffect(() => {
    if(users_android_data && users_data) {
      // setChartAndroidData(handleTotalNewUsersInLastNDays(users_android_data, 15));
      // setChartData(handleTotalNewUsersInLastNDays(users_data, 15));
      setChartAndroidData(handleTotalNewUsers(users_android_data));
      setChartData(handleTotalNewUsers(users_data));
    }
  }, [users_android_data, users_data])

  useEffect(() => {
    if(chartData && charAndroidData) {

      setLimit(moment().add(-15, "days").valueOf());
      setOptions({
            stroke: {
              curve: 'smooth'
            },
            markers: {
              size: 0,

            },
            labels: chartData.label,
            xaxis: {
              type: 'datetime',
              // min: moment().format('DD MMM'),
              min: limit,
              max:  moment().valueOf(),

            },
            chart:{
              id:'users-per-day',
              toolbar:{
                // show: false
              },
              zoom: {
                autoScaleYaxis: true
              }
            },
            dataLabels: {
              enabled: true
            },
          });
      setSeries([
        {
          type: 'area',
          name: 'New users',
          data: chartData.data
        },
        {
          type: 'area',
          name: 'Android New users',
          data: charAndroidData.data
        },
      ]);

      setSelection('15days');
    }
  }, [chartData, charAndroidData ])

  useEffect(()=> {
    if(chartData && charAndroidData) {
      let initialDate: any;
      switch (selection) {
        case '15days':
          initialDate = moment().add(-15, "days").valueOf()
          break;
        case 'month':
          initialDate = moment().add(-1, "month").valueOf()
          break;
        case '6months':
          initialDate = moment().add(-6, "month").valueOf()
          break;
        case 'year':
          initialDate = moment().add(-1, "year").valueOf()
          break;
        default:
      }
      setLimit(initialDate);
      setOptions({
        stroke: {
          curve: 'smooth'
        },
        markers: {
          size: 0,

        },
        labels: chartData.label,
        xaxis: {
          type: 'datetime',
          min: initialDate,
          max:  moment().valueOf(),
        },
        chart:{
          id:'users-per-day',
          toolbar:{
            // show: false
          },
          zoom: {
            autoScaleYaxis: true
          }
        },
        dataLabels: {
          enabled: true
        },
      })

    }
  },[selection])

  const updateData = (typeSelection: string) => {
    setSelection(typeSelection);
  }

  if (users_data_isLoading || users_android_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(users_android_error || users_data_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  if(!series ) {
    return <></>
  }
  return (
      <Fragment>
        <div id={'chart'}>
        <div className="toolbar">
          <Button id="15days"
                  size="small"
                  variant={ selection === '15days'? "contained": "outlined"}
                  className="mr-1"
                  color="primary"
                  onClick={() => updateData('15days')}
          >
            15D
          </Button>
          &nbsp;
          <Button id="month"
                  size="small"
                  variant={ selection === 'month'? "contained": "outlined"}
                  className="mr-1"
                  color="primary"
                  onClick={() => updateData('month')}>
            1M
          </Button>
          &nbsp;
          <Button id="6months"
                  onClick={() => updateData('6months')}
                  size="small"
                  variant={ selection === '6months'? "contained": "outlined"}
                  className="mr-1"
                  color="primary">
            6M
          </Button>
          &nbsp;
          <Button id="one_year"
                  onClick={() => updateData('year')}
                  size="small"
                  variant={ selection === 'year'? "contained": "outlined"}
                  color="primary"
                  className="mr-1">
            1Y
          </Button>
        </div>
        <Chart ref={chartRef} options={options} series={series}  type="area" />
        </div>
      </Fragment>
  );
}

