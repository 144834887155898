import React, {Fragment, Suspense, useEffect, useRef, useState} from 'react';
import Chart from "react-apexcharts";
import {useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import { handleUserWithProductsPie} from "../../../utilities/Charts/ChartsService";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";

export default function UsersProductPie() {
  const {
    products_users_count_data,
    products_users_count_isLoading,
    products_users_count_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const [chartData, setChartData] = useState<any>();

  const [options, setOptions] = useState<any>();
  const [series, setSeries] = useState<any>();

  useEffect(() => {
    if(products_users_count_data) {
      setChartData(handleUserWithProductsPie(products_users_count_data));
    }

  }, [products_users_count_data])


  useEffect(()=>{
    if(chartData) {
      const seriesData = chartData
      const labels = ['With Products', 'Without Products'];
      const series = _.values(seriesData)
      setOptions({
        labels: labels,
        chart: {
          width: 100,
          height: 100,
          type: 'pie',
        },
        legend: {
          formatter: (val:any, opts:any) => {
            return 'Users ' + val +': ' +opts.w.globals.series[opts.seriesIndex]
          }
        },
        responsive: [{
          breakpoint: 100,
          options: {
            chart: {
              width: 100,
              height: 100,
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      });
      setSeries(
          series
      );

    }

  },[chartData])

  if (products_users_count_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }
  if(products_users_count_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  if(!series ) {
    return <></>
  }

  return (
      <Fragment>
        <Chart options={options} series={series}  type="pie" key={'UserProductPie'} height={300}/>
      </Fragment>
  )
}
