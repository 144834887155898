import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import _ from "lodash";

import {
  CircularProgress,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination, Grid, Button
} from '@material-ui/core';
import { TableFooter } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import TablePaginationActions from '../PaginationActions';
import SearchBar from "material-ui-search-bar";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";

const styles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  rowStyle: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    height: 72
  },
  closeInfo: {
    textAlign: 'right',
    height: 48
  },
  searchBar: {
    marginBottom: 16
  }
}));

type Props = {}

export default function UsersList () {
  const {
    products_users_count_data,
    products_users_count_isLoading,
    products_users_count_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const dispatch = useDispatch();

  const classes = styles();
  const [formatedData, setFormatedData] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const emptyRows = tableData ? rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage) : 0;

  const handleChangePage = (event:any, newPage: any) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatData = (brandsDataList: any) => {
    return _.orderBy(brandsDataList, ['total'],['desc']);
  }
  const requestSearch = (searchedVal: string) => {
    const filteredRows = formatedData.filter((row: any) => {
      return row.user_id.toString().includes(searchedVal);
    });
    setTableData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    if(products_users_count_data && products_users_count_data.data){
      setFormatedData(formatData(products_users_count_data.data))
      setTableData(formatData(products_users_count_data.data))
    }
  },[products_users_count_data])

  const openUserProductsList = (userId: any) => {
    dispatch(dataRequestActions.getUserProductsList({userId}));
  }

  if (products_users_count_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(products_users_count_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  if(!tableData) {
    return (<></>)
  }
  return (
      <Fragment>
        <SearchBar
            placeholder={'User Id'}
            value={searched}
            onChange={(searchVal: any) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            className={classes.searchBar}
        />
        <TableContainer className="mb-4" component={Paper}>
          <Table aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell className={classes.head}>User Id</TableCell>
                <TableCell className={classes.head} align="right">Total Products</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData && (rowsPerPage > 0
                      ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tableData
              ).map((row: any) => (
                  <TableRow key={'__'+row.user_id} className={classes.rowStyle}>
                    <TableCell component="th" scope="row"
                               onClick={()=> openUserProductsList(row.user_id)}>
                      <b>{row.user_id}</b>
                    </TableCell>
                    <TableCell align="right">
                      {row.total}
                    </TableCell>
                  </TableRow>
              ))}

              {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={tableData? tableData.length: 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Fragment>
  )
}
