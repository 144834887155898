import React, {Fragment, useEffect} from 'react';
import { Grid } from '@material-ui/core';

import {WrapperSimple, PageTitle} from '../../components';

import KPIsTable from "../../tables/KPIsTable";
import {useDispatch} from "react-redux";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";
import { ProductsOverallData } from '../../tables';

/*
  KPIs page
 */

export default function KPIs() {

  const dispatch = useDispatch();

  // Getting the data from the API
  useEffect(() => {
    /* Getting products per users data */
    dispatch(dataRequestActions.getProductsUsersStatistics())

  }, [])


  return (
      <Fragment>
        <PageTitle
            titleHeading="KPIs"
            titleDescription="ZenOwn KPIs"
        />
        <Grid container spacing={4} justify={"center"}>
          <Grid item lg={12}>
            <WrapperSimple sectionHeading="KPIs Data">
              <div style={{paddingLeft: 10, paddingRight:10}}>
                <KPIsTable />
              </div>
            </WrapperSimple>
          </Grid>
        </Grid>
      </Fragment>
  );
}
