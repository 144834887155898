import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as invoiceProcessActions from "../../../stores/modules/invoiceProcess/actions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import {makeStyles} from "@material-ui/core/styles";
import {IStoreState} from "../../../stores/createStore";
import * as yup from "yup";
import {CircularProgress} from "@material-ui/core";
import ProcessInvoice from "../ProcessInvoice";


const styles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red'
  }

}));

type Props = {
  onSubmitAction: () => void;
}

export default function AddProductFromInvoice (props: Props) {

  const classes = styles();

  const {
    user_invoice_forward_data,
    user_invoice_forward_isLoading,
    user_invoice_forward_error
  } = useSelector((state:IStoreState) => state.invoiceRequest)


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(invoiceProcessActions.processUserInvoiceClear());
  }, [])

  const validationSchema = yup.object({
    user_email: yup
        .string()
        .email()
        .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      user_email: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const userInvoiceRequest =  {
        email: values.user_email
      };
      dispatchData(userInvoiceRequest);
    },
  });

  const dispatchData = (signupRequest: any) => {
    dispatch(invoiceProcessActions.findUserInvoiceForwarding(signupRequest))
  }

  if(user_invoice_forward_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }


  if(user_invoice_forward_data) {
    return (
        <Fragment>
          <ProcessInvoice onSubmitAction={props.onSubmitAction}  userData={user_invoice_forward_data} processingType={'invoiceForward'}/>
        </Fragment>
    )
  }


  return (
      <Fragment>

        <form className={classes.form}  onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="user_email"
                  fullWidth
                  id="user_email"
                  name="user_email"
                  label="Enter user's email"
                  value={formik.values.user_email}
                  onChange={formik.handleChange}
                  error={formik.touched.user_email && Boolean(formik.errors.user_email)}
                  helperText={formik.touched.user_email && formik.errors.user_email}
              />
            </Grid>
          </Grid>
          {user_invoice_forward_error && <>
              <p className={classes.errorMessage}>
                  Something when wrong, try again or contact the admin!
              </p>
          </>}
          {user_invoice_forward_data === null  && <>
              <p className={classes.errorMessage}>
                  No user found!
              </p>
          </>}
          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
          >
            Search
          </Button>
        </form>
      </Fragment>
  );

};
