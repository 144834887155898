import { ActionType } from 'typesafe-actions';
import { takeLatest, all,call,put } from 'redux-saga/effects'
import * as actions from './actions'
import UserService from "../../../utilities/Services/UserService";
import AuthenticationService from '../../../utilities/Services/AuthenticationService';



export function* login ({payload}: ActionType<typeof actions.login>): any {
  try {
    const service = new UserService();
    const authenticationService = new AuthenticationService();
    const authenticationData = yield call(service.login, payload.loginData);
    yield call(authenticationService.authenticate, authenticationData);
    const accountData = {userName: authenticationData.userName, userEmail: authenticationData.userEmail}
    yield put(actions.loginSuccess(accountData));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.loginError(error));
  }
}

export function* signup ({payload}: ActionType<typeof actions.signup>): any {
  try {
    const service = new UserService();
    const authenticationData = yield call(service.signup, payload.signupData);
    yield put(actions.signupSuccess(authenticationData));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.loginError(error));
  }
}

export function* logout (): any {
  try {
    const service = new UserService();
    const authenticationService = new AuthenticationService();
    yield call(service.logout);
    yield call(authenticationService.deAuthenticate);
    yield put(actions.logoutSuccess());
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.logoutError(error));
  }
}

export default all([
  takeLatest('@@account/LOGIN', login),
  takeLatest('@@account/SIGNUP', signup),
  takeLatest('@@account/LOGOUT', logout)

])
