import React, {Fragment, useEffect} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Avatar,
  Box,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  Divider, Grid
} from '@material-ui/core';

import avatar5 from '../../../assets/images/avatars/avatar5.jpg';
import {useDispatch, useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import AuthenticationService from "../../../utilities/Services/AuthenticationService";
import * as accountActions from "../../../stores/modules/account/actions";
const HeaderUserBox = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { account } = useSelector((state:IStoreState) => state.account);
  const dispatch = useDispatch();

  if(!account) {
    const {userName, userEmail} = new AuthenticationService().getAccountData();
    if(userName && userEmail) {
      dispatch(accountActions.setUserData({userName, userEmail}));
    }
  }

  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    // window.console.log('Logout')
    dispatch(accountActions.logout())
  }
  useEffect(() => {
    if(!account){
      // window.console.log('Logout done')
    }
  },[account])


  if(props.displaySimple) {
    return (
        <Fragment>
          {account &&<>
              <Button
                  color="inherit"
                  onClick={handleClick}
                  className="px-3 text-left btn-inverse d-flex align-items-center">
                  <Box justifyContent={"flex-end"}>
                      <span className="font-weight-bold pt-2 line-height-1">Logged user: </span><br/>
                      <span className="font-weight-bold pt-2 line-height-1">{account.userEmail}</span>
                  </Box>
              </Button>
              <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  getContentAnchorEl={null}
                  open={Boolean(anchorEl)}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}
                  onClose={handleClose}
                  className="ml-2">
                  <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                      <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                          <Box>
                              <Avatar sizes="44" alt={account.userName} className="text-capitalize">
                                {account.userName[0]} </Avatar>
                          </Box>
                          <div className="pl-3  pr-3">
                              <div className="text-capitalize font-weight-bold text-center pt-2 line-height-1">
                                {account.userName}
                              </div>
                              <span className="text-black-50 text-center">
                                {account.userEmail}
                              </span>
                          </div>
                          <Divider className="w-100 mt-2" />
                          <ListItem button disabled={true}>My Account</ListItem>
                          <ListItem button  disabled={true}>Profile settings</ListItem>
                          <Divider className="w-100" />
                          <ListItem button onClick={logout}>Logout</ListItem>
                      </List>
                  </div>
              </Menu>
          </>
          }
        </Fragment>
    )
  }

  return (
      <Fragment>
        <Button
            color="inherit"
            onClick={handleClick}
            className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
          <Box>
            <Avatar sizes="44" alt="Emma Taylor" src={avatar5} />
          </Box>
          <div className="d-none d-xl-block pl-3">
            <div className="font-weight-bold pt-2 line-height-1">Ryan Kent</div>
            <span className="text-white-50">Senior React Developer</span>
          </div>
          <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
        </Button>

        <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            onClose={handleClose}
            className="ml-2">
          <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
            <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
              <Box>
                <Avatar sizes="44" alt="Emma Taylor" src={avatar5} />
              </Box>
              <div className="pl-3  pr-3">
                <div className="font-weight-bold text-center pt-2 line-height-1">
                  Ryan Kent
                </div>
                <span className="text-black-50 text-center">
                Senior React Developer
              </span>
              </div>
              <Divider className="w-100 mt-2" />
              <ListItem button>My Account</ListItem>
              <ListItem button>Profile settings</ListItem>
              <ListItem button>Active tasks</ListItem>
              <Divider className="w-100" />
              <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
                <Tooltip arrow title="Twitter">
                  <Button color="default" className="text-twitter">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </span>
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
          </div>
        </Menu>
      </Fragment>
  );
}

export default HeaderUserBox;
