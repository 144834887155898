import {makeStyles} from "@material-ui/core/styles";
import { FieldArrayRenderProps } from "formik";
import _ from "lodash";
import React, {Fragment, useEffect, useState} from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import { Button } from "@material-ui/core";
import UploadService from "../../../utilities/Services/UploadService";
import {Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import missingImage from "../../../styles/images/no-img.jpeg";

export  interface IViewImagesImagesProps {
  label?: string;
  images?: any[];
}

type Props = IViewImagesImagesProps

const styles = makeStyles(theme => ({
  container: {
    paddingTop: '20px',
    fontSize: 20
  },
  button: {
    border: '1px dashed rgba(43, 46, 52, 0.5)',
    padding: '8px 0',
    boxShadow: 'none',
    textTransform: 'none',
  },
  buttonWithError: {
    color: 'red'
  },
  fileImage: {
    width: '300px',
    cursor: 'zoom-in'
  },
  fileUrl: {
    paddingLeft: 20,
    fontWeight: 'bold',
    alignContent: 'center'
  },
  iconButton:{
    fontSize: 20,
  },
  textButton: {
    fontSize: 12,
    marginBottom: 10,
    marginLeft: 10
  },
  regularImage: {

  },
  selectedImage: {
    backgroundColor: "lightgray"
  },
  imagesContainer: {
    textAlign: "center",
    '&:hover p': {
      opacity: 1
    }
  },
  legend: {
    cursor: 'zoom-in',
    position: "absolute",
    bottom: 10,
    left: "60%",
    marginLeft: "-45%",
    width: "70%",
    borderRadius: 10,
    background: "#000",
    color: "#fff",
    padding: 10,
    fontSize: 12,
    textAlign: "center",
    opacity: 0.25,
    transition: "opacity .35s ease-in-out",
    '&:hover': {
      opacity: 1
    }
  }
}))

const ViewImages = (props: any) => {

  const imagesUrl = process.env.REACT_APP_IMAGES_BASE_URL

  const { images, label, name, isMultiple, values, onImageSelect, error, helperText } = props;

  const [files, setFiles] = useState<any>();
  const classes = styles();

  useEffect(() => {
    if (images){
      images.forEach( (photo: any) => {
        photo.thumbImg = `${imagesUrl}/thumbnail/${photo.uuid}.jpg`;
        photo.originalImg = `${imagesUrl}/original/${photo.uuid}.jpg`;
      })
      setFiles(images);
    }
  }, []);


  const renderImages = () => {
    if(!files){
      return
    }

    return files.map((fileInfo: any, index: number) => {

      const onOpenImageClick = () => {
        window.open(fileInfo.originalImg, "_blank")
      }

      const addMissingImage = (evt: any) => {
        evt.target.src = missingImage
      }

      let photoType = ''
      switch (fileInfo.type) {
        case 0:
          photoType = 'Invoice';
          break;
        case 1:
          photoType = '';
          break;
        case 2:
          photoType = 'Warranty';
          break;
      }

      return <Fragment>
        <Grid item={true} sm={4}
              key={`image_file_${index}`}
              justify="center"
              alignItems="center"
              className={classes.regularImage}
        >
          <Grid container={true}
                style={{padding: 20}}
                direction="row"
                justify="center"
                alignItems="center"

          >
            <Grid item={true} sm={12} className={classes.imagesContainer}>
              <img
                  onError={addMissingImage}
                  src={fileInfo.thumbImg}
                  className={classes.fileImage}
                  onClick={onOpenImageClick}/>
              <p className={classes.legend}>{photoType}</p>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    })
  };

  return <Fragment>
    <div className={classes.container}>
      <Grid container={true}>
        <Grid container={true}>
          <Grid item={true} sm={12}>
            <b>Existing photos</b>
            <hr/>
          </Grid>
          <Grid item={true} sm={12} >
            <Grid container={true} spacing={2}>
              { renderImages() }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  </Fragment>
}

export default ViewImages;
