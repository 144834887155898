import ApiServices, {IDataResponse} from "./ApiServices";
import {getProductVideos} from "../../stores/modules/dataRequest/sagas";

export interface IUserIdRequest {
  userId: string;
}

export interface IUserEmailRequest {
  email: string;
}
class DataService {

  public getOverallUsersData = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_users_data');
  }

  public getAndroidUsersData = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_users_android_data');
  }

  public getProductStatisticData = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_product_statistic');
  }

  public getProductUsersStatisticData = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_product_users_statistic');
  }

  public getProductPhotosStatisticData = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_product_photos');
  }

  public getProductByBrandData = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_product_by_brand');
  }

  public getProductByCategoryData = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_product_by_category');
  }


  public getProductsUsersCount = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_users_products_count');
  }
  public getUserProductsList = async (userId: IUserIdRequest): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/get_user_products', userId);
  }

  public getProducts = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_products');
  }

  public getProductsProcessedToday = async ():Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_products_processed_today');
  }

  public getProductsOverAllData = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_overall_products_data');
  }

  public getSells = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/get_sells');
  }

  public getUserInvoiceForward = async (userEmail: IUserEmailRequest): Promise<IDataResponse<any>> => {

    return await ApiServices.postRequest('/get_user_invoice_forward', userEmail);
  }


  public processUserInvoice = async (invoiceData: any): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/process_user_invoice', invoiceData);
  }

  public finish_process = async (invoiceData: any): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/finish_process', invoiceData);
  }

  public processAddProductData = async (invoiceData: any): Promise<IDataResponse<any>> => {
    return await ApiServices.putRequest('/process_add_product_data', invoiceData);
  }



  public getUserPushNotifications =  async (userId: IUserIdRequest): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/get_user_push_notifications', {userId});
  }

  public getProductVideos =  async (productId: number): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/get_product_videos', {productId});
  }

  public sendVideoProductPush = async (sendPushId: number): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/send_video_product_push', {sendPushId});
  }

  public addYoutubeVideo = async (addYoutubeVideoRequest: any): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/add_youtube_video', {addYoutubeVideoRequest});
  }

  public deleteYoutubeVideo = async (deleteYoutubeVideoRequest: any): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/delete_youtube_video', {deleteYoutubeVideoRequest});
  }

  public kpiData = async (kpiDataRequest: any): Promise<IDataResponse<any>> => {
    return await ApiServices.postRequest('/kpi_data', { kpiDataRequest });
  }
}

export default DataService;
