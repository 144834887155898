import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import PeopleUserIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';;

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  PeopleUserIcon: PeopleUserIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  MonetizationOnIcon: MonetizationOnIcon
};

export default [
  {
    label: 'Navigation menu',
    content: [
      {
        label: "Dashboards",
        icon: iconsMap["DashboardTwoToneIcon"],
        description: "Dashboard section",
        to: "/dashboard"
      },
      {
        label: "KPIs",
        icon: iconsMap["BarChartIcon"],
        description: "KPIs section",
        to: "/kpis"
      },
      {
        label: "Users",
        icon: iconsMap["PeopleUserIcon"],
        description: "Users section",
        to: "/users"
      },
      {
        label: "Products",
        icon: iconsMap["ListAltIcon"],
        description: "Products section",
        to: "/products_list",
        /*content: [
          {
            label: "Products Dashboard",
            icon: iconsMap["DashboardIcon"],
            description: "Products section",
            to: "/products",
          },
          {
            label: "Products List",
            icon: iconsMap["ListAltIcon"],
            description: "Products section",
            to: "/products_list",
          }
        ]*/
      },
      {
        label: "Sells",
        icon: iconsMap["MonetizationOnIcon"],
        description: "Sells section",
        to: "/sells"
      },
    ]
  }
];


/*
{
  label: "Products",
  icon: iconsMap["DashboardTwoToneIcon"],
  content: [
    {
      label: "Default",
      description: "This is a dashboard page example built using this template.",
      to: "/DashboardDefault"
    }
  ]
}
 */
