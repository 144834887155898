import { ActionType } from 'typesafe-actions';
import { takeLatest, all,call,put } from 'redux-saga/effects'
import * as actions from './actions'
import DataService from "../../../utilities/Services/DataService";

export function* getUserData (): any {
  try {
    const dataService = new DataService();
    const user_data = yield call(dataService.getOverallUsersData);
    yield put(actions.usersDataRequestSuccess(user_data));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.usersDataRequestError(error));
  }
}

export function* getAndroidUserData (): any {
  try {
    const dataService = new DataService();
    const android_users_data = yield call(dataService.getAndroidUsersData);
    yield put(actions.usersAndroidDataRequestSuccess(android_users_data));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.usersAndroidDataRequestError(error));
  }
}

export function* getProductStatisticData (): any {
  try {
    const dataService = new DataService();
    const product_statistic = yield call(dataService.getProductStatisticData);
    yield put(actions.getProductsStatisticsSuccess(product_statistic));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsStatisticsError(error));
  }
}

export function* getProductUsersStatisticData (): any {
  try {
    const dataService = new DataService();
    const product_users_statistic = yield call(dataService.getProductUsersStatisticData);
    yield put(actions.getProductsUsersStatisticsSuccess(product_users_statistic));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsUsersStatisticsError(error));
  }
}

export function* getProductPhotosStatisticData (): any {
  try {
    const dataService = new DataService();
    const product_photos_statistic = yield call(dataService.getProductPhotosStatisticData);
    yield put(actions.getProductsPhotosStatisticsSuccess(product_photos_statistic));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsPhotosStatisticsError(error));
  }
}

export function* getProductByBrandsData (): any {
  try {
    const dataService = new DataService();
    const product_by_brand = yield call(dataService.getProductByBrandData);
    yield put(actions.getProductsByBrandsSuccess(product_by_brand));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsByBrandsError(error));
  }
}

export function* getProductByCategoryData (): any {
  try {
    const dataService = new DataService();
    const products_by_category= yield call(dataService.getProductByCategoryData);
    yield put(actions.getProductsByCategoriesSuccess(products_by_category));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsByCategoriesError(error));
  }
}

export function* getProductsUsersCount (): any {
  try {
    const dataService = new DataService();
    const product_users_count = yield call(dataService.getProductsUsersCount);
    yield put(actions.getProductsUsersCountSuccess(product_users_count));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsUsersCountError(error));
  }
}

export function* getUserProductsList ({payload}: ActionType<typeof actions.getUserProductsList>): any {
  try {
    const dataService = new DataService();
    const product_users_count = yield call(dataService.getUserProductsList, payload.userId);
    yield put(actions.getUserProductsListSuccess(product_users_count));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getUserProductsListError(error));
  }
}

export function* getProducts ({payload}: ActionType<typeof actions.getUserProductsList>): any {
  try {
    const dataService = new DataService();
    const product = yield call(dataService.getProducts);


    yield put(actions.getProductsSuccess(product));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsError(error));
  }
}

export function* getProductsProcessedToday (): any {
  try {
    const dataService = new DataService();
    const products_processed_today = yield call(dataService.getProductsProcessedToday);
    yield put(actions.getProductsProcessedTodaySuccess(products_processed_today));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsProcessedTodayError(error));
  }
}

export function* getProductsOverAllData ({payload}: ActionType<typeof actions.getUserProductsList>): any {
  try {
    const dataService = new DataService();
    const product = yield call(dataService.getProductsOverAllData);
    yield put(actions.getProductsOverallDataSuccess(product));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductsOverallDataError(error));
  }
}

export function* getSells (): any {
  try {
    const dataService = new DataService();
    const sells = yield call(dataService.getSells);
    yield put(actions.getSellsSuccess(sells));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getSellsError(error));
  }
}

export function* getPushNotifications({payload}: ActionType<typeof actions.getPushNotifications>): any {
  try {
    const dataService = new DataService();
    const userPushNotifications = yield call(dataService.getUserPushNotifications, payload.userId);
    yield put(actions.getPushNotificationsSuccess(userPushNotifications));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getPushNotificationsError(error));
  }
}

export function* getProductVideos({payload}: ActionType<typeof actions.getProductVideos>): any {
  try {
    const dataService = new DataService();
    const userPushNotifications = yield call(dataService.getProductVideos, payload.productId);
    yield put(actions.getProductVideosSuccess(userPushNotifications));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.getProductVideosError(error));
  }
}

export function* sendVideoProductPush ({payload}: ActionType<typeof actions.sendVideoProductPush>): any {
  try {
    const dataService = new DataService();
    const sendVideoProductPush = yield call(dataService.sendVideoProductPush, payload.sendPushId);
    yield put(actions.sendVideoProductPushSuccess(sendVideoProductPush));
  }
  catch (error: any) {
    // TODO:Implement error call
    yield put(actions.sendVideoProductPushError(error));
  }
}

export function* addYoutubeVideo ({payload}: ActionType<typeof actions.addYoutubeVideo>): any {
  try {
    const dataService = new DataService();
    const addYoutubeVideo = yield call(dataService.addYoutubeVideo, payload.addYoutubeVideoRequest);
    yield put(actions.addYoutubeVideoSuccess(addYoutubeVideo));
  }
  catch (error: any) {
    yield put(actions.addYoutubeVideoError(error));
  }
}

export function* deleteYoutubeVideo ({payload}: ActionType<typeof actions.deleteYoutubeVideo>): any {
  try {
    const dataService = new DataService();
    const deleteYoutubeVideo = yield call(dataService.deleteYoutubeVideo, payload.deleteYoutubeVideoRequest);
    yield put(actions.deleteYoutubeVideoSuccess(deleteYoutubeVideo));
  }
  catch (error: any) {
    yield put(actions.deleteYoutubeVideoError(error));
  }
}

export function* kpiData ({payload}: ActionType<typeof actions.kpiData>): any {
  try {
    const dataService = new DataService();
    const kpiData = yield call(dataService.kpiData, payload.kpiDataRequest);
    yield put(actions.kpiDataSuccess(kpiData));
  }
  catch (error: any) {
    yield put(actions.kpiDataError(error));
  }
}

export default all([
  takeLatest('@@dataRequest/USERS_DATA',getUserData),
  takeLatest('@@dataRequest/USERS_ANDROID_DATA',getAndroidUserData),
  takeLatest('@@dataRequest/PRODUCT_STATISTIC_DATA',getProductStatisticData),
  takeLatest('@@dataRequest/PRODUCT_USERS_STATISTIC_DATA',getProductUsersStatisticData),
  takeLatest('@@dataRequest/PRODUCT_PHOTO_STATISTIC_DATA',getProductPhotosStatisticData),
  takeLatest('@@dataRequest/PRODUCT_BY_BRAND_DATA',getProductByBrandsData),
  takeLatest('@@dataRequest/PRODUCTS_USERS_DATA',getProductsUsersCount),
  takeLatest('@@dataRequest/PRODUCT_BY_CATEGORY_DATA',getProductByCategoryData),
  takeLatest('@@dataRequest/USER_PRODUCTS_LIST_DATA',getUserProductsList),
  takeLatest('@@dataRequest/GET_PRODUCTS',getProducts),
  takeLatest('@@dataRequest/GET_PRODUCTS_OVERALL_DATA',getProductsOverAllData),
  takeLatest('@@dataRequest/GET_SELLS',getSells),
  takeLatest('@@dataRequest/GET_USER_PUSH_NOTIFICATIONS',getPushNotifications),
  takeLatest('@@dataRequest/GET_PRODUCT_VIDEOS',getProductVideos),
  takeLatest('@@dataRequest/SEND_VIDEO_PRODUCT_PUSH',sendVideoProductPush),
  takeLatest('@@dataRequest/ADD_YOUTUBE_VIDEO', addYoutubeVideo),
  takeLatest('@@dataRequest/DELETE_YOUTUBE_VIDEO', deleteYoutubeVideo),
  takeLatest('@@dataRequest/KPI_DATA', kpiData),
  takeLatest('@@dataRequest/GET_PRODUCTS_PROCESSED_TODAY', getProductsProcessedToday)
])
