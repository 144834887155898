import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as invoiceProcessActions from "../../../stores/modules/invoiceProcess/actions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import {makeStyles} from "@material-ui/core/styles";
import {IStoreState} from "../../../stores/createStore";
import * as yup from "yup";
import {Card, CardContent, CardHeader, Checkbox, CircularProgress} from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import { Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FileUpload from "../FileUpload";
import _ from "lodash";
import {v1 as uuidv1} from "uuid";
import SelectImages from "../SelectImages";
import ViewImages from "../ViewImages";
import { FormControlLabel } from '@material-ui/core';
import {ProductInfo} from "../index";
import moment from "moment";


const styles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {

    color: 'red'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardHeader: {
    backgroundColor: 'lightgray',
    fontSize: 18,
    fontWeight: 'bold'
  },
  success: {
    color: "green",
    fontWeight: "bold"
  },
  rejectButton: {
    backgroundColor: "#ff0000",
    color: "white",
    "&:hover": {
      backgroundColor: "#fd394a",
    }
  }
}));

type Props = {
  userData?: any
  processingType: 'invoiceForward' | 'invoiceScan' | 'addFromProductInvoice' | 'addProductData'
  selectedProduct?: any
  onSubmitAction: () => void
}

export default function ProcessInvoice (props: Props) {

  const classes = styles();

  const {
    user_invoice_forward_data,
    user_invoice_processing,
    user_invoice_processing_isLoading,
    user_invoice_processing_error
  } = useSelector((state:IStoreState) => state.invoiceRequest)

  const dispatch = useDispatch();

  const setInitialValues = () => {
    if(props.processingType === 'addProductData' && props.selectedProduct) {
      const {selectedProduct} = props;
      return {
        product_name: selectedProduct.name,
        product_brand:selectedProduct.brand,
        product_price: selectedProduct.price,
        product_price_currency: selectedProduct.currency,
        purchase_date: selectedProduct.purchaseDate,
        warranty_date: selectedProduct.warrantyDate,
        purchase_location: selectedProduct.purchaseLocation,
        uuid: selectedProduct.uuid,
        userID: props.selectedProduct.userID,
        processing_type: props.processingType,
        purchase_file: initialFileArray,
        confirm_reject: false,
        reject: false
      }
    }
    else {
      return {
        product_name: '',
        product_brand:'',
        product_price: '',
        product_price_currency: '',
        purchase_date: '',
        warranty_date: '',
        purchase_location:'',
        purchase_file: initialFileArray,
        processing_type: props.processingType,
        confirm_reject: false
      }
    }
  }

  const setValidationSchema = () => {
    if(props.processingType === 'addProductData' && props.selectedProduct) {
      return yup.object({
        // product_name: yup.string().required(),
      });
    }
    else {
      return yup.object({
        product_name: yup.string().required(),
        product_brand: yup.string(),
        product_price: yup.number(),
        product_price_currency: yup.string(),
        purchase_date: yup.date(),
        warranty_date:  yup.date(),
        purchase_location: yup.string(),
        purchase_file: yup.array().min(1),
      });
    }
  }
  /*
  const validationSchema = yup.object({
    product_name: yup.string().required(),
    product_brand: yup.string(),
    product_price: yup.number(),
    product_price_currency: yup.string(),
    purchase_date: yup.date(),
    warranty_date:  yup.date(),
    purchase_location: yup.string(),
    purchase_file: yup.array().min(1),
  });*/

  const initialFileArray: any[] = [];

  const [confirmReject, setConfirmReject] = useState<boolean>(false);
  const [showConfirmRejectError, setShowConfirmRejectError] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: setInitialValues(),
    validationSchema: setValidationSchema(),
    onSubmit: (values) => {

      if (props.processingType === 'addFromProductInvoice' ) {
        const generatedUUID = uuidv1().toUpperCase();

        const userInvoiceRequest =  {
          name: values.product_name,
          brand: values.product_brand ,
          price: values.product_price ,
          currency: values.product_price_currency ,
          warrantyDate: values.warranty_date ,
          purchaseDate: values.purchase_date ,
          purchaseLocation: values.purchase_location,
          authUserID: props.selectedProduct.authUserID,
          uuid: generatedUUID,
          processing_type: props.processingType,
          invoiceFiles: values.purchase_file,
          userID: props.selectedProduct.userID
        };

        dispatchData(userInvoiceRequest);
        props.onSubmitAction();
      }
      else if (props.processingType === 'addProductData' ) {
        const userInvoiceRequest =  {
          name: values.product_name,
          brand: values.product_brand ,
          price: values.product_price ,
          currency: values.product_price_currency ,
          warrantyDate: values.warranty_date ,
          purchaseDate: values.purchase_date ,
          purchaseLocation: values.purchase_location,
          // authUserID:user_invoice_forward_data.authUserID,
          uuid: props.selectedProduct.uuid,
          processing_type: props.processingType,
          userID: props.selectedProduct.userID,
          reject: false,
          rejectConfirm: false
        };

        if(values.reject === true){
          userInvoiceRequest.reject = values.reject;
          userInvoiceRequest.rejectConfirm = confirmReject;
        }

        dispatchData(userInvoiceRequest);
        props.onSubmitAction();
      }
      else {

        if(!user_invoice_forward_data.uuid){
          user_invoice_forward_data.uuid = uuidv1().toUpperCase();
        }

        const userInvoiceRequest =  {
          name: values.product_name,
          brand: values.product_brand ,
          price: values.product_price ,
          currency: values.product_price_currency ,
          warrantyDate: values.warranty_date ,
          purchaseDate: values.purchase_date ,
          purchaseLocation: values.purchase_location,
          authUserID:user_invoice_forward_data.authUserID,
          uuid: user_invoice_forward_data.uuid,
          processing_type: props.processingType,
          invoiceFiles: values.purchase_file,
          userID: user_invoice_forward_data.userID
        };

        dispatchData(userInvoiceRequest);
        props.onSubmitAction();
      }
    },

  });

  useEffect(() => {
    dispatch(invoiceProcessActions.processUserInvoiceClear());
    formik.resetForm();
  }, []);

  const dispatchData = (signupRequest: any) => {
    if(props.processingType === 'addProductData') {
      dispatch(invoiceProcessActions.processAddProductData(signupRequest))
    }
    else {
      dispatch(invoiceProcessActions.processUserInvoice(signupRequest))
    }
  }


  if(user_invoice_processing_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }
  if(user_invoice_processing){
    return <Fragment>
      <Grid container={true}>
        <Grid item={true} xs={12} className={classes.success}>
          {confirmReject && props.processingType === "addProductData" &&
          <p>Invoice rejected!</p>
          }
          {!confirmReject && props.processingType === "addProductData" &&
          <p>Adding Product data completed!</p>
          }
          {props.processingType === "addFromProductInvoice" &&
          <p>Adding the product from invoice!</p>
          }
          {props.processingType === "invoiceForward" &&
          <p>Invoice Forward completed!</p>
          }
        </Grid>
        <Grid item={true} xs={12}>
          <ProductInfo productObject={user_invoice_processing} isViewMode={true} />
        </Grid>
      </Grid>
    </Fragment>
  }

  const currencies = ['EUR', 'USD', 'GBP', 'CHF', 'AUD', 'BRL', 'BGN', 'HRK', 'CZK', 'DKK', 'HUF', 'INR', 'JPY', 'KRW', 'MXN', 'NOK', 'PLN', 'RON', 'RUB', 'SEK', 'TRY', 'CAD', 'UGX', 'OTH'];

  const renderCurrenciesSelector = () => {
    return currencies.map((currency) => {
      return <option key={currency} value={currency}>{currency}</option>
    })
  }

  const onRemoveFileHandler = (keyToRemove: string) => {
    _.remove(formik.values.purchase_file, (value: any)=>{
      return value.key === keyToRemove
    });
  }

  const onImageSelect = (fileUUID: string) => {

    const purchase_files = formik.values.purchase_file;
    // Checking if image is selected
    if (_.indexOf(purchase_files, fileUUID) > -1){
      // IF yes, removes it
      _.remove(purchase_files, (files) => {
        return files === fileUUID
      })

      formik.values.purchase_file = purchase_files;
    }
    else {
      // IF NOT we add it
      purchase_files.push(fileUUID)
      // _.set(formik.values,'purchase_file', purchase_files );
      formik.values.purchase_file = purchase_files;
    }
  }

  const handleConfirmRejectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmReject(event.target.checked);
    setShowConfirmRejectError(false);
  }

  const onRejectSubmitClick = () => {
    if(confirmReject === false){
      setShowConfirmRejectError(true);
    }
    else {
      setShowConfirmRejectError(false);
      formik.values.reject = true;
      formik.submitForm()
          .then(()=> {
            formik.values.reject = false;
          });
    }
  }

  const setWarrantyDate = () => {
    if(formik.values.warranty_date === ''){
      formik.values.warranty_date = formik.values.purchase_date;
    }
  }

  const getErrorMessage = () => {
    if(user_invoice_processing_error.message){
      return(<>
        <p className={classes.errorMessage}>
          <b>{user_invoice_processing_error.message}</b>
        </p>
      </>)
    }
    return(<>
      <p className={classes.errorMessage}>
        Something when wrong, try again or contact the admin!
      </p>
    </>)
  }

  return (
      <Fragment>
        <form className={classes.form}  onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="product_name"
                  fullWidth
                  id="product_name"
                  name="product_name"
                  label="Enter product name"
                  value={formik.values.product_name}
                  onChange={formik.handleChange}
                  error={formik.touched.product_name && Boolean(formik.errors.product_name)}
                  helperText={formik.touched.product_name && formik.errors.product_name}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="product_brand"
                  fullWidth
                  id="product_brand"
                  name="product_brand"
                  label="Enter product brand"
                  value={formik.values.product_brand}
                  onChange={formik.handleChange}
                  error={formik.touched.product_brand && Boolean(formik.errors.product_brand)}
                  helperText={formik.touched.product_brand && formik.errors.product_brand}
              />
            </Grid>

            <Grid item xs={12}>

              <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="product_price"
                  id="product_price"
                  name="product_price"
                  label="Enter product price"
                  value={formik.values.product_price}
                  onChange={formik.handleChange}
                  error={formik.touched.product_price && Boolean(formik.errors.product_price)}
                  helperText={formik.touched.product_price && formik.errors.product_price}
                  style={{width:'48%', marginRight: '4%'}}
              />

              <FormControl variant="outlined" margin="normal" style={{width:'48%'}}>
                <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                <Select
                    variant="outlined"
                    native
                    value={formik.values.product_price_currency}
                    onChange={formik.handleChange}
                    label="Currency"
                    id="product_price_currency"
                    name="product_price_currency"
                    inputProps={{
                      name: 'product_price_currency',
                      id: 'product_price_currency',
                    }}
                >
                  <option aria-label="None" value="" />
                  {renderCurrenciesSelector()}
                </Select>
              </FormControl>

            </Grid>

            <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  margin="normal"
                  id="purchase_date"
                  label="Purchase Date"
                  type="date"
                  // defaultValue="2017-05-24"
                  value={formik.values.purchase_date}
                  // onChange={formik.handleChange}
                  onChange={(e)=>{
                    formik.handleChange(e);
                    if(!formik.values.warranty_date) {
                      const warrantyValue = moment(e.currentTarget.value)
                          .add('2', 'years')
                          .format('YYYY-MM-DD');
                      formik.setFieldValue('warranty_date', warrantyValue);
                    }

                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width:'48%', marginRight: '4%'}}
              />

              <TextField
                  variant="outlined"
                  margin="normal"
                  id="warranty_date"
                  label="Waranty Date"
                  type="date"
                  // defaultValue="2017-05-24"
                  value={formik.values.warranty_date}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width:'48%'}}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="purchase_location"
                  fullWidth
                  id="purchase_location"
                  name="purchase_location"
                  label="Purchase Location"
                  value={formik.values.purchase_location}
                  onChange={formik.handleChange}
                  error={formik.touched.purchase_location && Boolean(formik.errors.purchase_location)}
                  helperText={formik.touched.purchase_location && formik.errors.purchase_location}
              />
            </Grid>


            <Grid item xs={12}>
              { props.selectedProduct && props.selectedProduct.addType === 'invoiceScan' &&
              <SelectImages
                  images={props.selectedProduct.Photos}
                  id="purchase_file"
                  name="purchase_file"
                  values={formik.values.purchase_file}
                  isMultiple={false}
                  onImageSelect={onImageSelect}
                  error={formik.touched.purchase_file && Boolean(formik.errors.purchase_file)}
                  helperText={formik.touched.purchase_file && formik.errors.purchase_file}
              />

              }
              {
                props.processingType !== 'addFromProductInvoice' &&
                props.processingType !== 'addProductData' &&
                <FileUpload id="purchase_file"
                            name="purchase_file"
                            label="Upload file"
                            values={formik.values.purchase_file}
                            isMultiple={false}
                            onRemoveFile={onRemoveFileHandler}
                />
              }

            </Grid>

          </Grid>
          {user_invoice_processing_error && getErrorMessage()
          }

          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
          >
            Save
          </Button>

          {
            props.processingType === 'addProductData' && <Grid item xs={12}>
                <Card>
                    <CardHeader
                        className={classes.cardHeader}
                        title={"Reject Invoice"}
                    />
                    <CardContent>
                      {
                        showConfirmRejectError && <div>
                            <p className={classes.errorMessage}>
                                <b>To reject invoice you have confirm it by pressing the Confirm Reject checkmark</b>
                            </p>
                        </div>
                      }
                        <label>
                            <Checkbox
                                checked={confirmReject}
                                onChange={handleConfirmRejectChange}
                                name="confirm_reject"
                                id="confirm_reject"
                                color="primary"
                            />
                            Confirm reject
                        </label>
                        <br />
                        <Button variant="contained" className={classes.rejectButton} onClick={onRejectSubmitClick}>
                            Reject Invoice
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
          }
          {
            props.processingType === 'addProductData' &&
            <Grid item xs={12}>
                <ViewImages
                    images={props.selectedProduct.Photos}
                    id="purchase_file"
                    name="purchase_file"
                    values={formik.values.purchase_file}
                    isMultiple={false}
                    onImageSelect={onImageSelect}
                    error={formik.touched.purchase_file && Boolean(formik.errors.purchase_file)}
                    helperText={formik.touched.purchase_file && formik.errors.purchase_file}
                />
            </Grid>
          }
        </form>
      </Fragment>
  );

}
