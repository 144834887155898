import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as invoiceProcessActions from "../../../stores/modules/invoiceProcess/actions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import {makeStyles} from "@material-ui/core/styles";
import {IStoreState} from "../../../stores/createStore";
import * as yup from "yup";
import {CircularProgress} from "@material-ui/core";
import ProcessInvoice from "../ProcessInvoice";


const styles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red'
  }

}));

type Props = {
  selectedProduct?: any,
  onSubmitAction: () => void
}

export default function AddProductFromExistingInvoice (props: Props) {

  const classes = styles();

  const dispatch = useDispatch();

  useEffect(() => {

  }, [])

  if(props.selectedProduct){
    return (
        <Fragment>
          <ProcessInvoice
              onSubmitAction={props.onSubmitAction}
              selectedProduct={props.selectedProduct}
              processingType={'addFromProductInvoice'}/>
        </Fragment>
    )
  }
  else {
    return <CircularProgress />
  }


};
