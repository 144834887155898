import React, {Fragment, Suspense, useEffect, useRef, useState} from 'react';
import Chart from "react-apexcharts";
import {useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import {handleProductPhotosStatistic} from "../../../utilities/Charts/ChartsService";
import {CircularProgress} from "@material-ui/core";
import _ from "lodash";

export default function PhotosPerProduct() {
  const {
    products_photo_statistic_data,
    products_statistic_data,
    products_photo_statistic_isLoading,
    products_photo_statistic_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const [chartData, setChartData] = useState<any>();

  const [options, setOptions] = useState<any>();
  const [series, setSeries] = useState<any>();

  useEffect(() => {
    if(products_photo_statistic_data && products_statistic_data) {
      const totalProducts = products_statistic_data.totalProducts
      setChartData(handleProductPhotosStatistic(totalProducts, products_photo_statistic_data));
    }

  }, [products_photo_statistic_data, products_statistic_data])


  useEffect(()=>{
    if(chartData) {
      const seriesData = chartData.seriesData
      const labels = _.keys(seriesData)
      const series = _.values(seriesData)
      setOptions({
        labels: labels,
        chart: {
          width: 300,
          type: 'donut',
        },
        legend: {
          formatter: (val:any, opts:any) => {
            return val + " Photos - " + opts.w.globals.series[opts.seriesIndex]
          }
        },
        responsive: [{
          breakpoint: 200,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      });
      setSeries(
        series
      );

    }

  },[chartData])

  if (products_photo_statistic_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }
  if(products_photo_statistic_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  if(!series ) {
    return <></>
  }

  return (
      <Fragment>
        <Chart options={options} series={series}  type="donut" />
      </Fragment>
  )
}
