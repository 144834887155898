import { DataRequestAction, IDataRequestState} from './types';

const initialState: IDataRequestState = {
  isLoading: false,
  users_data_isLoading: false,
  users_android_isLoading: false,
  products_statistic_isLoading: false,
  products_users_statistic_isLoading: false,

  products_photo_statistic_isLoading: false,
  products_by_brands_isLoading: false,
  products_by_category_isLoading: false,
  products_users_count_isLoading: false,
  user_products_list_isLoading: false,
  products_data_isLoading: false,
  products_overall_data_isLoading: false,
  sells_isLoading: false,
  push_notification_isLoading:false,
  product_videos_isLoading: false,
  send_video_push_isLoading: false,
  add_youtube_video_push_isLoading: false,
  delete_youtube_video_isLoading: false,
  kpi_data_isLoading: false,
  products_processed_today_isLoading: false
}

export default function dataRequest(state = initialState, action: DataRequestAction) :IDataRequestState{
  switch (action.type) {
    case "@@dataRequest/USERS_DATA":
      return {
        ...state,
        users_data_isLoading: true,
        users_data_error: undefined,
      };
    case "@@dataRequest/USERS_DATA_SUCCESS":
      return {
        ...state,
        users_data_isLoading: false,
        users_data: action.payload.dataRequested
      };
    case "@@dataRequest/USERS_DATA_ERROR":
      return {
        ...state,
        users_data_isLoading: false,
        users_data_error: action.payload.error,
      };

    case "@@dataRequest/USERS_ANDROID_DATA":
      return {
        ...state,
        users_android_isLoading: true,
        users_android_error: undefined,
      };
    case "@@dataRequest/USERS_ANDROID_DATA_SUCCESS":
      return {
        ...state,
        users_android_isLoading: false,
        users_android_data: action.payload.dataRequested
      };
    case "@@dataRequest/USERS_ANDROID_DATA_ERROR":
      return {
        ...state,
        users_android_isLoading: false,
        users_android_error: action.payload.error,
      };

    case "@@dataRequest/PRODUCT_STATISTIC_DATA":
      return {
        ...state,
        products_statistic_isLoading: true,
        products_statistic_error: undefined,
      };
    case "@@dataRequest/PRODUCT_STATISTIC_DATA_SUCCESS":
      return {
        ...state,
        products_statistic_isLoading: false,
        products_statistic_data: action.payload.dataRequested
      };
    case "@@dataRequest/PRODUCT_STATISTIC_DATA_ERROR":
      return {
        ...state,
        products_statistic_isLoading: false,
        products_statistic_error: action.payload.error,
      };

    case "@@dataRequest/PRODUCT_USERS_STATISTIC_DATA":
      return {
        ...state,
        products_users_statistic_isLoading: true,
        products_users_statistic_error: undefined,
      };
    case "@@dataRequest/PRODUCT_USERS_STATISTIC_DATA_SUCCESS":
      return {
        ...state,
        products_users_statistic_isLoading: false,
        products_users_statistic_data: action.payload.dataRequested
      };
    case "@@dataRequest/PRODUCT_USERS_STATISTIC_DATA_ERROR":
      return {
        ...state,
        products_users_statistic_isLoading: false,
        products_users_statistic_error: action.payload.error,
      };

    case "@@dataRequest/PRODUCT_PHOTO_STATISTIC_DATA":
      return {
        ...state,
        products_photo_statistic_isLoading: true,
        products_photo_statistic_error: undefined,
      };
    case "@@dataRequest/PRODUCT_PHOTO_STATISTIC_DATA_SUCCESS":
      return {
        ...state,
        products_photo_statistic_isLoading: false,
        products_photo_statistic_data: action.payload.dataRequested
      };
    case "@@dataRequest/PRODUCT_PHOTO_STATISTIC_DATA_ERROR":
      return {
        ...state,
        products_photo_statistic_isLoading: false,
        products_photo_statistic_error: action.payload.error,
      };

    case "@@dataRequest/PRODUCT_BY_BRAND_DATA":
      return {
        ...state,
        products_by_brands_isLoading: true,
        products_by_brands_error: undefined,
      };
    case "@@dataRequest/PRODUCT_BY_BRAND_SUCCESS":
      return {
        ...state,
        products_by_brands_isLoading: false,
        products_by_brands_data: action.payload.dataRequested
      };
    case "@@dataRequest/PRODUCT_BY_BRAND_ERROR":
      return {
        ...state,
        products_by_brands_isLoading: false,
        products_by_brands_error: action.payload.error,
      };

    case "@@dataRequest/PRODUCT_BY_CATEGORY_DATA":
      return {
        ...state,
        products_by_category_isLoading: true,
        products_by_category_error: undefined,
      };
    case "@@dataRequest/PRODUCT_BY_CATEGORY_SUCCESS":
      return {
        ...state,
        products_by_category_isLoading: false,
        products_by_category_data: action.payload.dataRequested
      };
    case "@@dataRequest/PRODUCT_BY_CATEGORY_ERROR":
      return {
        ...state,
        products_by_category_isLoading: false,
        products_by_category_error: action.payload.error,
      };

    case "@@dataRequest/PRODUCTS_USERS_DATA":
      return {
        ...state,
        products_users_count_isLoading: true,
        products_users_count_error: undefined,
      };
    case "@@dataRequest/PRODUCTS_USERS_DATA_SUCCESS":
      return {
        ...state,
        products_users_count_isLoading: false,
        products_users_count_data: action.payload.dataRequested
      };
    case "@@dataRequest/PRODUCTS_USERS_DATA_ERROR":
      return {
        ...state,
        products_users_count_isLoading: false,
        products_users_count_error: action.payload.error,
      };

    case "@@dataRequest/USER_PRODUCTS_LIST_DATA":
      return {
        ...state,
        user_products_list_data: undefined,
        user_products_list_isLoading: true,
        user_products_list_error: undefined,
      };
    case "@@dataRequest/USER_PRODUCTS_LIST_CLEAR":
      return {
        ...state,
        user_products_list_data: undefined
      };
    case "@@dataRequest/USER_PRODUCTS_LIST_SUCCESS":
      return {
        ...state,
        user_products_list_isLoading: false,
        user_products_list_data: action.payload.dataRequested
      };
    case "@@dataRequest/USER_PRODUCTS_LIST_ERROR":
      return {
        ...state,
        user_products_list_isLoading: false,
        user_products_list_error: action.payload.error,
      };

    case "@@dataRequest/GET_PRODUCTS":
      return {
        ...state,
        products_data: undefined,
        products_data_isLoading: true,
        products_data_error: undefined,
      };
    case "@@dataRequest/GET_PRODUCTS_SUCCESS":
      return {
        ...state,
        products_data_isLoading: false,
        products_data: action.payload.dataRequested
      };
    case "@@dataRequest/GET_PRODUCTS_ERROR":
      return {
        ...state,
        products_data_isLoading: false,
        products_data_error: action.payload.error,
      };


    case "@@dataRequest/GET_PRODUCTS_PROCESSED_TODAY":
      return {
        ...state,
        products_processed_today: undefined,
        products_processed_today_isLoading: true,
        products_processed_today_error: undefined,
      };
    case "@@dataRequest/GET_PRODUCTS_PROCESSED_TODAY_SUCCESS":
      return {
        ...state,
        products_processed_today_isLoading: false,
        products_processed_today: action.payload.dataRequested
      };
    case "@@dataRequest/GET_PRODUCTS_PROCESSED_TODAY_ERROR":
      return {
        ...state,
        products_processed_today_isLoading: false,
        products_processed_today_error: action.payload.error,
      };

    case "@@dataRequest/GET_PRODUCTS_OVERALL_DATA":
      return {
        ...state,
        products_overall_data: undefined,
        products_overall_data_isLoading: true,
        products_overall_data_error: undefined,
      };
    case "@@dataRequest/GET_PRODUCTS_OVERALL_DATA_SUCCESS":
      return {
        ...state,
        products_overall_data_isLoading: false,
        products_overall_data: action.payload.dataRequested
      };
    case "@@dataRequest/GET_PRODUCTS_OVERALL_DATA_ERROR":
      return {
        ...state,
        products_overall_data_isLoading: false,
        products_overall_data_error: action.payload.error,
      };

    case "@@dataRequest/GET_SELLS":
      return {
        ...state,
        sells_data: undefined,
        sells_isLoading: true,
        sells_error: undefined,
      };
    case "@@dataRequest/GET_SELLS_SUCCESS":
      return {
        ...state,
        sells_isLoading: false,
        sells_data: action.payload.dataRequested
      };
    case "@@dataRequest/GET_SELLS_ERROR":
      return {
        ...state,
        sells_isLoading: false,
        sells_error: action.payload.error,
      };

    case "@@dataRequest/GET_USER_PUSH_NOTIFICATIONS":
      return {
        ...state,
        push_notification_data: undefined,
        push_notification_isLoading: true,
        push_notification_error: undefined,
      };
    case "@@dataRequest/GET_USER_PUSH_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        push_notification_isLoading: false,
        push_notification_data: action.payload.dataRequested
      };
    case "@@dataRequest/GET_USER_PUSH_NOTIFICATIONS_ERROR":
      return {
        ...state,
        push_notification_isLoading: false,
        push_notification_error: action.payload.error,
      };

    case "@@dataRequest/GET_PRODUCT_VIDEOS":
      return {
        ...state,
        product_videos_data: undefined,
        send_video_push: undefined,
        product_videos_isLoading: true,
        product_videos_error: undefined,
      };
    case "@@dataRequest/GET_PRODUCT_VIDEOS_SUCCESS":
      return {
        ...state,
        product_videos_isLoading: false,
        product_videos_data: action.payload.dataRequested
      };
    case "@@dataRequest/GET_PRODUCT_VIDEOS_ERROR":
      return {
        ...state,
        product_videos_isLoading: false,
        product_videos_error: action.payload.error,
      };

    case "@@dataRequest/SEND_VIDEO_PRODUCT_PUSH":
      return {
        ...state,
        send_video_push: undefined,
        send_video_push_isLoading: true,
        send_video_push_error: undefined,
      };
    case "@@dataRequest/SEND_VIDEO_PRODUCT_PUSH_SUCCESS":
      return {
        ...state,
        send_video_push: action.payload.data,
        send_video_push_isLoading: false,
        send_video_push_error: undefined,
      };
    case "@@dataRequest/SEND_VIDEO_PRODUCT_PUSH_ERROR":
      return {
        ...state,
        send_video_push: undefined,
        send_video_push_isLoading: false,
        send_video_push_error: action.payload.error,
      };


    case "@@dataRequest/ADD_YOUTUBE_VIDEO":
      return {
        ...state,
        add_youtube_video_push: undefined,
        add_youtube_video_push_isLoading: true,
        add_youtube_video_push_error: undefined,
      };
    case "@@dataRequest/ADD_YOUTUBE_VIDEO_SUCCESS":
      return {
        ...state,
        add_youtube_video_push: action.payload.dataRequested,
        add_youtube_video_push_isLoading: false,
        add_youtube_video_push_error: undefined,
      };
    case "@@dataRequest/ADD_YOUTUBE_VIDEO_ERROR":
      return {
        ...state,
        add_youtube_video_push: undefined,
        add_youtube_video_push_isLoading: false,
        add_youtube_video_push_error: action.payload.error,
      };



    case "@@dataRequest/DELETE_YOUTUBE_VIDEO":
      return {
        ...state,
        delete_youtube_video: undefined,
        delete_youtube_video_isLoading: true,
        delete_youtube_video_error: undefined,
      };
    case "@@dataRequest/DELETE_YOUTUBE_VIDEO_SUCCESS":
      return {
        ...state,
        delete_youtube_video: action.payload.dataRequested,
        delete_youtube_video_isLoading: false,
        delete_youtube_video_error: undefined,
      };
    case "@@dataRequest/DELETE_YOUTUBE_VIDEO_ERROR":
      return {
        ...state,
        delete_youtube_video: undefined,
        delete_youtube_video_isLoading: false,
        delete_youtube_video_error: action.payload.error,
      };


    case "@@dataRequest/KPI_DATA":
      return {
        ...state,
        kpi_data: undefined,
        kpi_data_isLoading: true,
        kpi_data_error: undefined,
      };

    case "@@dataRequest/KPI_DATA_SUCCESS":
      return {
        ...state,
        kpi_data: action.payload.dataRequested,
        kpi_data_isLoading: false,
        kpi_data_error: undefined,
      };

    case "@@dataRequest/KPI_DATA_ERROR":
      return {
        ...state,
        kpi_data: undefined,
        kpi_data_isLoading: false,
        kpi_data_error: action.payload.error,
      };

    default:
      return state;
  }
}
