import { InvoiceRequestAction, IInvoiceRequestState} from './types';

const initialState: IInvoiceRequestState = {
  isLoading: false,
  user_invoice_forward_isLoading: false,
  user_invoice_processing_isLoading: false,
}

export default function dataRequest(state = initialState, action: InvoiceRequestAction) :IInvoiceRequestState{
  switch (action.type) {

    case "@@dataRequest/FIND_USER_INVOICE_FORWARDING":
      return {
        ...state,
        user_invoice_forward_data: undefined,
        user_invoice_forward_isLoading: true,
        user_invoice_forward_error: undefined,
      };
    case "@@dataRequest/FIND_USER_INVOICE_FORWARDING_SUCCESS":
      return {
        ...state,
        user_invoice_forward_data: action.payload.dataRequested.data,
        user_invoice_forward_isLoading: false,
        user_invoice_forward_error: undefined,
      };
    case "@@dataRequest/FIND_USER_INVOICE_FORWARDING_ERROR":
      return {
        ...state,
        user_invoice_forward_data: undefined,
        user_invoice_forward_isLoading: false,
        user_invoice_forward_error: action.payload.error,
      };
    case "@@dataRequest/FIND_USER_INVOICE_FORWARDING_CLEAR":
      return {
        ...state,
        user_invoice_forward_data: undefined,
        user_invoice_forward_isLoading: false,
        user_invoice_forward_error: undefined,
      };


    case "@@dataRequest/PROCESS_USER_INVOICE":
      return {
        ...state,
        user_invoice_processing: undefined,
        user_invoice_processing_isLoading: true,
        user_invoice_processing_error: undefined,
      };
    case "@@dataRequest/PROCESS_USER_INVOICE_SUCCESS":
      return {
        ...state,
        user_invoice_processing: action.payload.dataRequested.data,
        user_invoice_processing_isLoading: false,
        user_invoice_processing_error: undefined,
      };
    case "@@dataRequest/PROCESS_USER_INVOICE_ERROR":
      return {
        ...state,
        user_invoice_processing: undefined,
        user_invoice_processing_isLoading: false,
        user_invoice_processing_error: action.payload.error,
      };
    case "@@dataRequest/PROCESS_USER_INVOICE_CLEAR":
      return {
        ...state,
        user_invoice_processing: undefined,
        user_invoice_processing_isLoading: false,
        user_invoice_processing_error: undefined,
      };

    case "@@dataRequest/PROCESS_ADD_PRODUCT_DATA":
      return {
        ...state,
        user_invoice_processing: undefined,
        user_invoice_processing_isLoading: true,
        user_invoice_processing_error: undefined,
      };
    case "@@dataRequest/PROCESS_ADD_PRODUCT_DATA_SUCCESS":
      return {
        ...state,
        user_invoice_processing: action.payload.dataRequested.data,
        user_invoice_processing_isLoading: false,
        user_invoice_processing_error: undefined,
      };
    case "@@dataRequest/PROCESS_ADD_PRODUCT_DATA_ERROR":
      return {
        ...state,
        user_invoice_processing: undefined,
        user_invoice_processing_isLoading: false,
        user_invoice_processing_error: action.payload.error,
      };

    case "@@dataRequest/FINISHING_PROCESS_INVOICE":
      return {
        ...state,
        user_invoice_processing: undefined,
        user_invoice_processing_isLoading: true,
        user_invoice_processing_error: undefined,
      };
    case "@@dataRequest/FINISHING_PROCESS_INVOICE_SUCCESS":
      return {
        ...state,
        user_invoice_processing: action.payload.dataRequested.data,
        user_invoice_processing_isLoading: false,
        user_invoice_processing_error: undefined,
      };
    case "@@dataRequest/FINISHING_PROCESS_INVOICE_ERROR":
      return {
        ...state,
        user_invoice_processing: undefined,
        user_invoice_processing_isLoading: false,
        user_invoice_processing_error: action.payload.error,
      };

    default:
      return state;
  }
}
