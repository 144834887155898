import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import {
  Hidden,
  IconButton,
  AppBar,
  Box,
  Button,
  Tooltip
} from '@material-ui/core';

import {connect, useDispatch, useSelector} from 'react-redux';

import projectLogo from '../../../styles/images/logo_circle.png';
import HeaderLogo from '../../components/HeaderLogo';
import HeaderUserBox from '../../components/HeaderUserbox';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import * as themeActions from "../../../stores/modules/theme/actions";
import {IStoreState} from "../../../stores/createStore";

const Header = (props:any) => {
  const dispatch = useDispatch();

  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
  } = useSelector((state: IStoreState) => state.theme);

  const toggleSidebarMobile = () => {
    dispatch(themeActions.setSidebarToggleMobile(!sidebarToggleMobile))
  };

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {})}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box
              className="app-logo-wrapper"
              title="ZenOwn">
              <Link to="/" className="app-logo-link">
                <IconButton
                  color="primary"
                  size="medium"
                  className="app-logo-btn">
                  <img
                    className="app-logo-img"
                    alt="ZenOwn"
                    src={projectLogo}
                  />
                </IconButton>
              </Link>
              <Hidden smDown>
                <Box className="app-logo-text">ZenOwn</Box>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <div />
          </Hidden>
          <Box className="d-flex align-items-center">
            <HeaderUserBox displaySimple={true} />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium">
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};


export default Header;

