import React, {Fragment, ReactChild, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import {
  DataGrid,
  GridFilterItem,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarExport,
  ValueGetterParams
} from '@material-ui/data-grid';
import { CircularProgress, Dialog, DialogTitle, DialogContent, Badge } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {
  AddProductFromInvoice,
  AddProductFromExistingInvoice,
  ProductInfo,
  AddProductData,
  AddProductVideoSection
} from "../../components";
import clsx from "clsx";
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { IconButton } from '@material-ui/core';
import {AddToPhotos, Description, DoneAllOutlined, DoneOutline, OndemandVideo, Queue} from '@material-ui/icons';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { Tooltip } from '@material-ui/core';
import * as invoiceProcessActions from "../../../stores/modules/invoiceProcess/actions";
import _ from 'lodash';
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";
import EditIcon from "@material-ui/icons/Edit";


const styles = makeStyles(theme => ({
  root: {
    '& .product-row': {
      color: '#000',
      fontWeight: '400',
      cursor: "pointer",
    },
    '& .product-row.deleted': {
      backgroundColor: '#d47483',
      color: '#595d64',
      fontWeight: '600',
      cursor: "pointer",
    },
  },
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  rowStyle: {
    cursor: "pointer",
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  deletedProduct: {
    textDecoration: "line-through",
    color: 'red !important',
    backgroundColor: theme.palette.error.light + ' !important',
    "MuiTableCell-body": {
      color: 'red !important',
    }
  },

  dialogContentSection: {
    minHeight: 620
  },

  dialogTitle: {
    fontSize: 29,
    fontWeight: "bolder",
    marginBottom:0
  },
  dialogTitleSection: {
    backgroundColor: "lightgray",
    marginBottom: '1rem'
  },

  dataGridStyle: {
    '.MuiDataGrid-row.Mui-odd': {
      backgroundColor: "aliceblue"
    }
  },
  showDeleted: {
    textAlign: 'right',
    paddingTop: 10
  },
  addProductButton: {
    border: "none",
    '&:hover':{
      border: "none",
    }
  },

  badge: {
    marginRight: 25,
    ':&span': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    }
  },
}));

type Props = {}

export default function ProductsListTable(props: Props) {

  const {
    products_data,
    products_data_isLoading,
    products_data_error,
    products_processed_today,
    products_processed_today_isLoading,
    products_processed_today_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const {
    user_invoice_processing,
    user_invoice_processing_isLoading,
    user_invoice_processing_error
  } = useSelector((state:IStoreState) => state.invoiceRequest )


  const dispatch = useDispatch();

  const classes = styles();
  const [formatedListData, setFormatedListData] = useState<any>();
  const [tableListData, setTableListData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [addProductDialog, setAddProductDialog] = useState<boolean>(false);

  const [selectedProduct, setSelectedProduct] = useState<any>();

  const [selectedInvoiceToAdd, setSelectedInvoiceToAdd] = useState<any>();
  const [openAddInvoiceDialog, setOpenAddInvoiceDialog] = useState<boolean>(false);
  const [openAddProductDataDialog, setOpenAddProductDataDialog] = useState<boolean>(false);
  const [openAddVideoDialog, setOpenAddVideoDialog] = useState<boolean>(false);

  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [filterModelItem, setFilterModelItem] = useState<GridFilterItem[] >([]);

  const [invoicesToProcess, setInvoicesToProcess] = useState<number>(0);
  const [showInvoicesToProcess, setShowInvoicesToProcess] = useState<boolean>(false);

  const emptyRows = tableListData ? rowsPerPage - Math.min(rowsPerPage, tableListData.length - page * rowsPerPage) : 0;

  const handleChangePage = (event:any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = formatedListData.filter((row: any) => {
      return row.brand.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setTableListData(filteredRows);
  };

  const handleRowSelection = (rowSelectionEvt: any) => {
    //  setSelectedProduct(rowSelectionEvt.data);
    // setDialogOpen(true)
  }

  const handleCellSelection = (cellSelectionEvt: any) => {
    if(cellSelectionEvt.field === "userID") {
      const filterByUserId = [{ columnField: 'userID', value: cellSelectionEvt.value, operatorValue: 'equals' }];
      setFilterModelItem(filterByUserId);
    }
    else if(cellSelectionEvt.field === "actions"  ||
        cellSelectionEvt.field === "actionsBtn"  ||
        (cellSelectionEvt.field === "name" && cellSelectionEvt.value === '')) {


    }
    else {
      setSelectedProduct(cellSelectionEvt.row);
      setDialogOpen(true)
    }
  }

  const displayProduct = (event: React.MouseEvent<unknown>, productObj: any) => {
    setSelectedProduct(productObj);
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  }

  const handleProductAddFromInvoice = () => {
    setDialogOpen(false);
    setSelectedInvoiceToAdd(selectedProduct)
    setOpenAddInvoiceDialog(true)
  }

  const handleProductVideo = () => {
    setDialogOpen(false);
    addProductVideo(selectedProduct.id);
  }

  const handleAddProductData = () => {
    setDialogOpen(false);
    setSelectedInvoiceToAdd(selectedProduct)
    setOpenAddProductDataDialog(true)
  }

  const handleAddProductCloseDialog = () => {
    setAddProductDialog(false);
    setOpenAddInvoiceDialog(false)
    setDialogOpen(false);
    dispatch( invoiceProcessActions.findUserInvoiceForwardingClear());
    dispatch(dataRequestActions.getProducts());
    dispatch(dataRequestActions.getProductsProcessedToday())
  }

  const handleAddProductOpenDialog = () => {
    setAddProductDialog(true);
  }

  const addProductFromInvoiceById = (productId: any) => {
    const productSelected = _.filter(products_data.data,(product:any )=>
        product.id === productId
    )
    if (productSelected && productSelected[0]){
      setSelectedInvoiceToAdd(productSelected[0])
      setOpenAddInvoiceDialog(true)
    }
  }

  const editProductDataById = (productId: any) => {
    const productSelected = _.filter(products_data.data,(product:any )=>
        product.id === productId
    )
    if (productSelected && productSelected[0]){
      setSelectedInvoiceToAdd(productSelected[0]);
      setOpenAddProductDataDialog(true);
    }
  }

  const addProductDataById = (productId: any) => {
    const productSelected = _.filter(products_data.data,(product:any )=>
        product.id === productId
    )
    if (productSelected && productSelected[0]){
      setSelectedInvoiceToAdd(productSelected[0])
      setOpenAddProductDataDialog(true)
    }
  }

  const handleAddProductFromInvoice = () => {
    dispatch(dataRequestActions.getProducts());
    dispatch(dataRequestActions.getProductsProcessedToday());
    setOpenAddInvoiceDialog(false)
  }

  const handleAddProductDataCloseDialog = () => {

    dispatch(dataRequestActions.getProducts());
    dispatch(dataRequestActions.getProductsProcessedToday());
    setOpenAddProductDataDialog(false)
  }

  const addProductVideo = (productId: any) => {
    const productSelected = _.filter(products_data.data,(product:any )=>
        product.id === productId
    )
    if (productSelected && productSelected[0]){
      setSelectedInvoiceToAdd(productSelected[0])
      setOpenAddVideoDialog(true)
    }
  }

  const handleAddVideoCloseDialog = () => {
    setOpenAddVideoDialog(false)
  }

  const handleInvoiceToProcess = () => {
    setShowInvoicesToProcess(!showInvoicesToProcess);
  }

  const handleFilterModelChange = (model: any) => {

    /* this is used to reset the filter model */
    if(model.filterModel.items.length > 0 && model.filterModel.items && filterModelItem[0] &&
        model.filterModel.items[0].value !== filterModelItem[0].value) {
      setFilterModelItem( model.filterModel.items);
    }
  }

  const finishProcessing = (productId: any, isSandBox: boolean) => {
    const productSelected = _.filter(products_data.data,(product:any )=>
        product.id === productId
    )
    if (productSelected && productSelected[0]){
      productSelected[0].processing = 1
      if(isSandBox) {
        productSelected[0].sandbox = 1
      }

      dispatch( invoiceProcessActions.finishingProcessInvoice(productSelected[0]));
    }
  }

  useEffect(()=> {
    if(user_invoice_processing) {
      dispatch(invoiceProcessActions.processUserInvoiceClear())
      dispatch(dataRequestActions.getProducts());
      dispatch(dataRequestActions.getProductsProcessedToday())
    }
  }, [user_invoice_processing])

  useEffect(()=> {
    if(!dialogOpen) {
      setSelectedProduct(undefined);
    }
  }, [dialogOpen])

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    if(products_data && products_data.data){

      setInvoicesToProcess(products_data.data.filter((d:any) => {
        return  d.deleted === 0
            && d.invoiceProcessing === 1
            && d.addType === 'invoiceScan' }).length)

      if(!showDeleted || !showInvoicesToProcess) {
        setFormatedListData(products_data.data.filter((d:any) => {return d.deleted === 0 && d.invoiceProcessing !== -1}))
        setTableListData(products_data.data.filter((d:any) => {return d.deleted === 0 && d.invoiceProcessing !== -1}))
      }
      if(showInvoicesToProcess){
        setFormatedListData(products_data.data.filter((d:any) => {return  d.deleted === 0
            && d.invoiceProcessing === 1
            && d.addType === 'invoiceScan'}))

        setTableListData(products_data.data.filter((d:any) => { return  d.deleted === 0
            && d.invoiceProcessing === 1
            && d.addType === 'invoiceScan'}))
      }
      if(showDeleted){
        setFormatedListData(products_data.data)
        setTableListData(products_data.data)
      }
    }
  },[products_data, showDeleted, showInvoicesToProcess])

  const renderDeletedRow = (params: ValueGetterParams) =>
      clsx( 'product-row',{
        deleted: params.getValue('deleted') === 1
      })

  const dataGridColumns  = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: renderDeletedRow,
      flex: 1,
      type: 'number',
      renderCell: (params: ValueGetterParams) => {

        let deviceType = ""
        const deviceValue :any = params.getValue('User');
        if(deviceValue !== undefined && deviceValue.deviceType!== undefined ) {
          if(deviceValue.deviceType === 0){
            deviceType = " (iOS)"
          }
          else if(deviceValue.deviceType === 1){
            deviceType = " (A)"
          }
        }

        if((params.getValue('addType') === 'invoiceScan'
                || params.getValue('addType') === 'invoiceForward')
            && params.getValue('invoiceProcessing') === 0
        ){
          return <div>{params.getValue('id')}{deviceType}  📝 </div>
        }
        return <div>{params.getValue('id')}{deviceType}</div>
      }
    },
    { field: 'userID', headerName: 'User Id',  cellClassName: renderDeletedRow,
      flex: 0.6,
    },
    { field: 'name', headerName: 'Name', cellClassName: renderDeletedRow,
      flex: 2,
      renderCell: (params: ValueGetterParams) => {
        if(params.getValue('invoiceProcessing') === -1) {
          return <div>INVOICE REJECTED</div>
        }

        return <div>{params.getValue('name')}</div>
      },
    },
    {
      field: 'actionsBtn', headerName: ' ', flex: 1.8, cellClassName: renderDeletedRow,
      renderCell: (params: ValueGetterParams) => {
        if(params.getValue('invoiceProcessing') === 1 &&
            params.getValue('deleted') === 0){
          if(params.getValue('name') === "" ){
            return <div>
              <Button
                  size="small"
                  variant={"outlined"}
                  className="mr-1 ml-1"
                  color="primary"
                  onClick={()=> addProductDataById(params.getValue('id'))}>
                Add product data from invoice
              </Button>
            </div>
          }
          else {
            return <div>
              <Button
                  size="small"
                  variant={"outlined"}
                  className="mr-1 ml-1"
                  color="primary"
                  onClick={()=> finishProcessing(params.getValue('id'), false)}>
                Finish Processing
              </Button>
            </div>
          }
        }
        return <div></div>;
      }
    },
    { field: 'nickname', headerName: 'Nickname', cellClassName: renderDeletedRow,
      flex: 1,
    },
    { field: 'brand', headerName: 'Brand', cellClassName: renderDeletedRow,
      flex: 1,
    },
    { field: 'productPrice',
      headerName: 'Price',
      cellClassName: renderDeletedRow,
      flex: 1,
      valueGetter: (params: ValueGetterParams) =>{
        if(params.getValue('price')) {
          return `${params.getValue('price') || ''} ${params.getValue('currency') || ''}`
        }
      }
    },
    { field: 'purchaseDate', headerName: 'Purchase Date', cellClassName: renderDeletedRow,
      flex: 1,
      renderCell: (params: ValueGetterParams) =>{
        if(params.getValue('purchaseDate') != '0000-00-00') {
          return <div>{params.getValue('purchaseDate')}</div>
        }
        return <div></div>
      }
    },
    { field: 'actions', headerName: 'Actions', cellClassName: renderDeletedRow,
      flex: 1.5,
      renderCell: (params: ValueGetterParams) => {
        const productId =   params.getValue('id')
        let actionsButtons: ReactChild[] = [];
        const productName = params.getValue('name')

        // It is is deleted no actions
        if(params.getValue('deleted') === 1) {
          return <></>
        }

        if(params.getValue('invoiceProcessing') === 1) {
          if(productName !== ''){
            actionsButtons.push(
                <Tooltip title="Edit product data">
                  <IconButton color="primary"
                              onClick={()=> editProductDataById(params.getValue('id'))}
                              aria-label="Edit product data" component="span">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
            )
            actionsButtons.push(
                <Tooltip title="Finish Processing">
                  <IconButton color="primary"
                              onClick={()=> finishProcessing(params.getValue('id'), false)}
                              aria-label="Finish Processing" component="span">
                    <DoneAllIcon />
                  </IconButton>
                </Tooltip>
            )
            if( process.env.REACT_APP_ENVIRONMENT !== 'production') {
              actionsButtons.push(
                  <Tooltip title="Finish Processing  (sandbox - ignore)">
                    <IconButton color="primary"
                                onClick={()=> finishProcessing(params.getValue('id'), true)}
                                aria-label="Finish Processing  (sandbox - ignore)" component="span">
                      <DoneOutline />
                    </IconButton>
                  </Tooltip>
              )
            }


          }
          else {
            actionsButtons.push(
                <Tooltip title="Add product data from invoice">
                  <IconButton color="primary"
                              onClick={()=> addProductDataById(params.getValue('id'))}
                              aria-label="Add product data from invoice" component="span">
                    <Description />
                  </IconButton>
                </Tooltip>
            )
          }
        }

        if(products_processed_today &&
            products_processed_today.data &&
            products_processed_today.data.find((el:any) => el.id === params.getValue('id'))){
          window.console.log('products_processed_today.data ', products_processed_today.data)
          actionsButtons.push(
              <Tooltip title="Send push for last product" >
                <IconButton color="primary"
                            onClick={()=> addProductFromInvoiceById(params.getValue('id'))}
                            aria-label="Send push for last product" component="span">
                  <AddToPhotos />
                </IconButton>
              </Tooltip>
          )
        }


        // REMOVED the button from the Actions menu
        if( // params.getValue('addType') === 'invoiceScan' &&
            params.getValue('invoiceProcessing') === 0 &&
            params.getValue('deleted') === 0
        ){
          actionsButtons.push(
              <Tooltip title="Add another product from invoice" >
                <IconButton color="primary"
                            onClick={()=> addProductFromInvoiceById(params.getValue('id'))}
                            aria-label="Add another product from invoice" component="span">
                  <AddToPhotos />
                </IconButton>
              </Tooltip>
          )
        }

        if(params.getValue('deleted') === 0 &&
            params.getValue('invoiceProcessing') !== 1 &&
            params.getValue('invoiceProcessing') !== -1){
          actionsButtons.push(
              <Tooltip title="Add Video">
                <IconButton color="primary"
                            onClick={() => addProductVideo(params.getValue('id'))}
                            aria-label="Add Video" component="span">
                  <OndemandVideo />
                </IconButton>
              </Tooltip>)


        }

        return <div key={`actions_btn_${productId}`}>
          {actionsButtons.map((actionButtonElem, index) =>
              <React.Fragment key={`elm_btn_${productId}_${index}`}>
                {actionButtonElem}
              </React.Fragment>) }
        </div>
      }
    },
    {
      field: 'deletedField',
      headerName: 'Status',
      cellClassName: renderDeletedRow,
      flex: 0.4,
      valueGetter: (params: ValueGetterParams) =>{
        if(params.getValue('deleted') === 1) {
          return `Deleted`
        }
        else {
          return ' '
        }
      },
      hide: !showDeleted
    }
  ];

  if (products_data_isLoading || user_invoice_processing_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(products_data_error) {
    return (
        <Fragment>
          <p>Something when wrong, try again or contact the admin!</p>
        </Fragment>
    )
  }

  const sortModel = [
    {
      field: 'id',
      sort: 'desc' as GridSortDirection,
    },
  ];

  const CustomToolBar = () => {

    return (
        <GridToolbarContainer>
          {
              invoicesToProcess > 0 &&
              <Badge className={classes.badge}
                     badgeContent={invoicesToProcess}
                     color="error" max={999}>
                  <Button
                      variant={showInvoicesToProcess ? "contained": "outlined"}
                      color="primary"
                      className={classes.addProductButton}
                      onClick={handleInvoiceToProcess}
                  >
                      Invoices to be processed
                  </Button>
              </Badge>
          }
          <Button
              variant="outlined"
              color="primary"
              className={classes.addProductButton}
              startIcon={<Queue />}
              onClick={handleAddProductOpenDialog}
          >
            Add new product from Invoice
          </Button>
          <GridToolbarExport />

        </GridToolbarContainer>
    );
  }

  if(!tableListData) {
    return (<></>)
  }

  return (
      <Fragment>

        <div style={{  width: '100%', flexGrow: 1}}
             className={classes.root}>
          <DataGrid
              autoHeight
              disableExtendRowFullWidth={true}
              columnBuffer={2}
              columns={dataGridColumns}
              rows={tableListData}
              pageSize={20}
              // onRowSelected={handleRowSelection}
              onCellClick={handleCellSelection}
              hideFooterSelectedRowCount={true}
              rowsPerPageOptions={[20, 50, 100,]}
              components={{
                Toolbar: CustomToolBar
              }}
              filterModel={{ items: filterModelItem}}
              onFilterModelChange={handleFilterModelChange}
              sortModel={sortModel}
          />
          <div className={classes.showDeleted}>
            <FormControlLabel
                value={showDeleted}
                control={<Checkbox color="primary" />}
                label="Show deleted"
                labelPlacement="end"
                onClick={()=>setShowDeleted(!showDeleted)}
            />
          </div>
        </div>

        {/* Product Detail dialog */}
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={dialogOpen}
            onClose={handleCloseDialog}
        >
          <DialogTitle className={classes.dialogTitleSection}>
            <p className={classes.dialogTitle}>Product Detail </p>
          </DialogTitle>
          <DialogContent className={classes.dialogContentSection}>
            <ProductInfo productObject={selectedProduct}
                         addProductData={handleAddProductData}
                         addProductFromInvoice={handleProductAddFromInvoice}
                         addProductVideo={handleProductVideo}

            />
          </DialogContent>
        </Dialog>

        {/* Add product from invoice dialog */}
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={addProductDialog}
            onClose={handleAddProductCloseDialog}
        >
          <DialogTitle className={classes.dialogTitleSection}>
            <p className={classes.dialogTitle}>Add product data from invoice</p>
          </DialogTitle>
          <DialogContent className={classes.dialogContentSection}>
            <AddProductFromInvoice onSubmitAction={handleAddProductCloseDialog}/>
          </DialogContent>
        </Dialog>

        {/* Add product data dialog */}
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={openAddProductDataDialog}
            onClose={handleAddProductDataCloseDialog}
        >
          <DialogTitle className={classes.dialogTitleSection}>
            <p className={classes.dialogTitle}>Add product data</p>
          </DialogTitle>
          <DialogContent className={classes.dialogContentSection}>
            <AddProductData onSubmitAction={handleAddProductDataCloseDialog} selectedProduct={selectedInvoiceToAdd} />
          </DialogContent>
        </Dialog>

        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={openAddInvoiceDialog}
            onClose={handleAddProductFromInvoice}
        >
          <DialogTitle className={classes.dialogTitleSection}>
            <p className={classes.dialogTitle}>Add product from existing invoice</p>
          </DialogTitle>
          <DialogContent className={classes.dialogContentSection}>
            <AddProductFromExistingInvoice
                onSubmitAction={handleAddProductFromInvoice}
                selectedProduct={selectedInvoiceToAdd}/>
          </DialogContent>
        </Dialog>

        {/* Youtube */}
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={openAddVideoDialog}
            onClose={handleAddVideoCloseDialog}
        >
          <DialogTitle className={classes.dialogTitleSection}>
            <p className={classes.dialogTitle}>Add Youtube Video to product</p>
          </DialogTitle>
          <DialogContent className={classes.dialogContentSection}>
            <AddProductVideoSection selectedProduct={selectedInvoiceToAdd}/>
          </DialogContent>
        </Dialog>

      </Fragment>
  )
}
