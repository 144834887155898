import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import _ from "lodash";

import {
  CircularProgress,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination
} from '@material-ui/core';
import { TableFooter } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import TablePaginationActions from '../PaginationActions';
import SearchBar from "material-ui-search-bar";

const styles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  rowStyle: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }
}));

type Props = {}

export default function ProductsOverallData(props: Props) {

  const {
    products_overall_data,
    products_overall_data_isLoading,
    products_overall_data_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const classes = styles();
  const [tableData, setTableData] = useState<any>();

  const formatData = (monthDataObj: any) => {

    let tableData : any[] = [];

    monthDataObj.forEach((monthData: any) => {
      const data = monthData.data;
      const row = {
        month: monthData.month,
        numberOfUsers: data.numberOfUsers,
        uniqueUsersAddedProduct: data.uniqueUsersAddedProduct,
        allProductsAdded: data.allProductsAdded,
        iPhoneUsers: data.iPhoneUsers,
        allProductsAddedByAllUsers: data.allProductsAddedByAllUsers,
      }
      tableData.push(row)
    })

    return tableData
  }

  useEffect(() => {
    if(products_overall_data){
      setTableData(formatData(products_overall_data))
    }
  },[products_overall_data])


  if (products_overall_data_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(products_overall_data_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  if(!tableData) {
    return (<></>)
  }

  return (
      <Fragment>
        <TableContainer className="mb-4" component={Paper}>
          <Table aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell className={classes.head}>Month</TableCell>
                <TableCell className={classes.head} >New Users</TableCell>
                <TableCell className={classes.head} >Unique Products Count</TableCell>
                <TableCell className={classes.head} >All Products <br/>
                  <span style={{fontSize:10}}>By users from this month</span></TableCell>
                <TableCell className={classes.head} >iPhone Products</TableCell>
                <TableCell className={classes.head} >All Products<br/>
                  <span style={{fontSize:10}}>By all users</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData && (tableData).map((row: any, index:number) => (
                  <TableRow key={'__'+index} className={classes.rowStyle}>
                    <TableCell component="th" scope="row">
                      <b>{row.month}</b>
                    </TableCell>
                    <TableCell>
                      {row.numberOfUsers}
                    </TableCell>
                    <TableCell>
                      {row.uniqueUsersAddedProduct}
                    </TableCell>
                    <TableCell>
                      {row.allProductsAdded}
                    </TableCell>
                    <TableCell>
                      {row.iPhoneUsers}
                    </TableCell>
                    <TableCell>
                      {row.allProductsAddedByAllUsers}
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Fragment>
  )
}
