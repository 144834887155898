import ApiServices, {IDataResponse} from "./ApiServices";

export interface IAwsS3 {
  ETag: string,
  VersionId: string,
  Location: string,
  key: string,
  Key: string,
  Bucket: string,
  size?:[number,number]
}

class UploadService {
  public uploadFile = async (file: FormData): Promise<IDataResponse<IAwsS3>> => {
    return ApiServices.postRequest('/file_upload', file);
  }

  public getFile = async (key: string, size: string): Promise<IDataResponse<any>> => {
    return ApiServices.postRequest('/get_file', {key, size});
  }

}

export default UploadService;
