import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import { DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  ValueGetterParams
} from '@material-ui/data-grid';

import {
  CircularProgress,
  Dialog, DialogTitle, DialogContent,
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import SellInfo from '../../components/SellInfo';

const styles = makeStyles(theme => ({
  root: {
    '& .product-row': {
      color: '#000',
      fontWeight: '600',
      cursor: "pointer",
    },
    '& .product-row.deleted': {
      backgroundColor: '#d47483',
      color: '#595d64',
      fontWeight: '600',
      cursor: "pointer",
    },
  },
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  rowStyle: {
    cursor: "pointer",
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  deletedProduct: {
    textDecoration: "line-through",
    color: 'red !important',
    backgroundColor: theme.palette.error.light + ' !important',
    "MuiTableCell-body": {
      color: 'red !important',
    }
  },

  dialogContentSection: {
    minHeight: 620
  },

  dialogTitle: {
    fontSize: 29,
    fontWeight: "bolder",
    marginBottom:0
  },
  dialogTitleSection: {
    backgroundColor: "lightgray",
    marginBottom: '1rem'
  },

  dataGridStyle: {
    '.MuiDataGrid-row.Mui-odd': {
      backgroundColor: "aliceblue"
    }
  },
  showDeleted: {
    textAlign: 'right',
    paddingTop: 10
  }

}));

type Props = {}

export default function ProductsListTable(props: Props) {

  const {
    sells_data,
    sells_isLoading,
    sells_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const classes = styles();
  const [formatedListData, setFormatedListData] = useState<any>();
  const [tableListData, setTableListData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedSell, setSelectedSell] = useState<any>();

  const [showDeleted, setShowDeleted] = useState<boolean>(false);

  const emptyRows = tableListData ? rowsPerPage - Math.min(rowsPerPage, tableListData.length - page * rowsPerPage) : 0;

  const handleChangePage = (event:any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = formatedListData.filter((row: any) => {
      return row.brand.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setTableListData(filteredRows);
  };

  const handleRowSelection = (rowSelectionEvt: any) => {
    setSelectedSell(rowSelectionEvt.data);
    setDialogOpen(true)
  }


  const displayProduct = (event: React.MouseEvent<unknown>, productObj: any) => {
    setSelectedSell(productObj);
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  useEffect(()=> {
    if(!dialogOpen) {
      setSelectedSell(undefined);
    }
  }, [dialogOpen])


  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    if(sells_data &&sells_data.data){

      if(!showDeleted) {
        setFormatedListData(sells_data.data.filter((d:any) => {return d.deleted === 0}))
        setTableListData(sells_data.data.filter((d:any) => {return d.deleted === 0}))
      }
      else {
        setFormatedListData(sells_data.data)
        setTableListData(sells_data.data)
      }
    }
  },[sells_data, showDeleted])

  const renderDeletedRow = (params: ValueGetterParams) =>
      clsx( 'product-row',{
        deleted: params.getValue('deleted') === 1
      })

  const dataGridColumns  = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: renderDeletedRow,
      flex: 0.4,
    },
    { field: 'title', headerName: 'Title',  cellClassName: renderDeletedRow,
      flex: 0.6,
    },
    { field: 'description', headerName: 'Description', cellClassName: renderDeletedRow,
      flex: 2,
    },
    { field: 'productName',
      headerName: 'Product', cellClassName: renderDeletedRow,
      flex: 1,
      valueGetter: (params: ValueGetterParams) =>{
        if(params.getValue('Product')) {
          const productObj:any = params.getValue('Product')
          if (productObj && productObj.name) {
            return `${productObj.name}`
          }
          return ` `

        }
      }
    },
    { field: 'productPrice',
      headerName: 'Price',
      cellClassName: renderDeletedRow,
      flex: 1,
      valueGetter: (params: ValueGetterParams) =>{
        if(params.getValue('price')) {
          return `${params.getValue('price') || ''} ${params.getValue('currency') || ''}`
        }
      }
    },
    { field: 'date',
      headerName: 'Date',
      cellClassName: renderDeletedRow,
      flex: 1,
    },
    {
      field: 'deletedField',
      headerName: 'Status',
      cellClassName: renderDeletedRow,
      flex: 0.4,
      valueGetter: (params: ValueGetterParams) =>{
        if(params.getValue('deleted') === 1) {
          return `Deleted`
        }
        else {
          return ' '
        }
      }
    }
  ];


  if (sells_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(sells_error) {
    return (
        <Fragment>
          <p>Something when wrong, try again or contact the admin!</p>
        </Fragment>
    )
  }
  const CustomToolBar = () => {
    return (
        <GridToolbarContainer>
          <GridToolbarExport />
        </GridToolbarContainer>
    );
  }
  if(!tableListData) {
    return (<></>)
  }

  return (
      <Fragment>

        <div style={{  width: '100%', flexGrow: 1}}
             className={classes.root}>
          <DataGrid
              autoHeight
              disableExtendRowFullWidth={true}
              columnBuffer={2}
              columns={dataGridColumns}
              rows={tableListData} pageSize={10}
              onRowSelected={handleRowSelection}
              hideFooterSelectedRowCount={true}
              rowsPerPageOptions={[10, 20, 100,]}
              components={{
                Toolbar: CustomToolBar
              }}
          />
          <div className={classes.showDeleted}>
            <FormControlLabel
                value={showDeleted}
                control={<Checkbox color="primary" />}
                label="Show deleted"
                labelPlacement="end"
                onClick={()=>setShowDeleted(!showDeleted)}
            />
          </div>
        </div>
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={dialogOpen}
            onClose={handleCloseDialog}
        >
          <DialogTitle className={classes.dialogTitleSection}>
            <p className={classes.dialogTitle}>Sell Detail </p>
          </DialogTitle>
          <DialogContent className={classes.dialogContentSection}>
            <SellInfo sellObject={selectedSell}/>
          </DialogContent>
        </Dialog>

      </Fragment>
  )
}
