import ApiServices, {IDataResponse } from './ApiServices';

export interface ILoginRequest {
  email: string;
  user_password: string;
}

export interface ISignupRequest {
  user_name: string;
  user_email: string;
  password: string;
  confirm_password: string;

}
export interface IAccountData {
  userName: string;
  userEmail: string;
}

class UserService {
  public login = async (loginRequest: ILoginRequest): Promise<IDataResponse<any>> => {
    const loginVars = {
      user_email: loginRequest.email,
      password: loginRequest.user_password
    }
    return await ApiServices.postRequest('/login', loginVars, {}, {},{authenticated: false});
  }

  public signup = async (signupRequest: ISignupRequest): Promise<IDataResponse<any>> => {
    const signupVars = {
      user_name: signupRequest.user_name,
      user_email: signupRequest.user_email,
      password: signupRequest.password,
      confirm_password: signupRequest.confirm_password
    }
    return await ApiServices.postRequest('/register', signupVars, {}, {},{authenticated: false});
  }

  public logout  = async (): Promise<IDataResponse<any>> => {
    return await ApiServices.getRequest('/logout',  {}, {},{authenticated: false});
  }
}

export default UserService;
