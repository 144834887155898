import {useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import React, {Fragment, Suspense, useEffect, useState} from "react";
import {handleGlobalStatisticData, handleTotalNewUsersInLastNDays} from "../../../utilities/Charts/ChartsService";
import Chart from 'react-apexcharts';
import {CircularProgress} from "@material-ui/core";

export default function GlobalStatistics () {

  const { products_statistic_data, products_statistic_isLoading, products_statistic_error } = useSelector((state:IStoreState) => state.dataRequest )

  const [chartData, setChartData] = useState<any>();

  const [options, setOptions] = useState<any>();
  const [series, setSeries] = useState<any>();

  useEffect(() => {
    if(products_statistic_data) {
      setChartData(handleGlobalStatisticData(products_statistic_data));
    }
  }, [products_statistic_data])

  useEffect(() => {
    if(chartData ) {
      setOptions(
          {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                barHeight: '100%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#fff']
              },
              formatter: function (val:any, opt:any) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+ "% (" + chartData.totals[opt.dataPointIndex]+ ")"
              },
              offsetX: 0,
              dropShadow: {
                enabled: true
              }
            },
            xaxis: {
              categories: chartData.labels
            },
            yaxis: {
              max: 100,
            },
            tooltip: {
              theme: 'dark',
              x: {
                show: true
              },
              y: {
                title: {
                  formatter: function () {
                    return ''
                  }
                },
                formatter: function (val:any, opt: any) {
                  return val + "% (" + chartData.totals[opt.dataPointIndex]+")";
                }
              }
            },
          }

      )
      setSeries([{ data: chartData.values}])
    }

  }, [chartData ])

  if (products_statistic_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(products_statistic_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  if(!series ) {
    return <></>
  }

  return (
      <Fragment>
        <Chart options={options} series={series}
                        type="bar" height={390} />
        <p><span style={{fontWeight:'bold'}}>Total products:</span> {products_statistic_data.totalProducts}</p>
      </Fragment>

  );
}
