import React, {Fragment, useEffect} from 'react';
import {useDispatch} from "react-redux";
import { Grid } from '@material-ui/core'
  ;
import {PageTitle, WrapperSimple} from "../../components";
import { ProductsByBrands } from "../../tables";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";

/*
  Products page
 */
export default function Products() {

  const dispatch = useDispatch();

  // Getting the data from the API
  useEffect(() => {
    dispatch(dataRequestActions.getProductsByBrands())
    dispatch(dataRequestActions.getProductsByCategories())
  }, [])

  return (
      <Fragment>
        <PageTitle
            titleHeading="Products Dashboard"
            titleDescription="Products dashboard"
        />
        <Grid container spacing={4} justify={"center"}>
          <Grid item xs={12}>
            <WrapperSimple sectionHeading="Products by brands">
              <ProductsByBrands />
            </WrapperSimple>
          </Grid>
          {/*
          <Grid item xs={12}>
            <WrapperSimple sectionHeading="Products by categories">
              <ProductsByCategory/>
            </WrapperSimple>
          </Grid>
          */}
        </Grid>
      </Fragment>
  )
}
