import React, {Fragment, useEffect} from 'react';
import { Grid } from '@material-ui/core';

import {WrapperSimple, PageTitle} from '../../components';
import {UserPerWeek, GlobalUsersPerDay, GlobalStatistics, ProductsPerUser, PhotosPerProduct} from "../../charts";
import {useDispatch} from "react-redux";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";
import { ProductsOverallData } from '../../tables';

/*
  Dashboard page
 */

export default function Dashboard() {

  const dispatch = useDispatch();

  // Getting the data from the API
  useEffect(() => {
    /* Getting products per users data */
    dispatch(dataRequestActions.getProductsUsersStatistics())

    /* Getting Overall users data */
    dispatch(dataRequestActions.usersDataRequest())

    /* Getting Android users data */
    dispatch(dataRequestActions.usersAndroidRequestData())

    /* Getting statistic data */
    dispatch(dataRequestActions.getProductsStatistics())

    /* Getting photos per product data */
    dispatch(dataRequestActions.getProductsPhotosStatistics())

    /* Getting the products overall data */
    dispatch(dataRequestActions.getProductsOverallData())

  }, [])


  return (
      <Fragment>
        <PageTitle
            titleHeading="Dashboard"
            titleDescription="ZenOwn dashboard"
        />
        <Grid container spacing={4} justify={"center"}>
          <Grid item xs={12} md={8} lg={8}>
            <WrapperSimple sectionHeading="Global data">
              <ProductsPerUser />
            </WrapperSimple>
            <WrapperSimple sectionHeading="Products overall data by month">
              <ProductsOverallData />
            </WrapperSimple>
            <WrapperSimple sectionHeading="Users Per Day">
              <GlobalUsersPerDay />
            </WrapperSimple>
            <WrapperSimple sectionHeading="Cumulative Users per week">
              <UserPerWeek />
            </WrapperSimple>
            <WrapperSimple sectionHeading="Global Statistics">
              <GlobalStatistics />
            </WrapperSimple>

            <WrapperSimple sectionHeading="Photos per product data ">
              <PhotosPerProduct />
            </WrapperSimple>

          </Grid>
          <Grid item xs={12} md={8}>
          </Grid>
        </Grid>
      </Fragment>
  );
}
