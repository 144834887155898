import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './styles/theme';
import {LeftSidebar, PresentationLayout} from "./views/layouts";
import {Login, Dashboard, Users, Products, ProductsList, Sells} from './views/pages';

import AuthenticationService from "./utilities/Services/AuthenticationService";
import NotFound from "./views/pages/notFound";
import KPIs from './views/pages/kpis';


const Routes =  () => {

  const location = useLocation();

  const authenticationService = new AuthenticationService();
  const isAuthenticated = authenticationService.isAuthenticated()

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return(
      <ThemeProvider theme={MuiTheme}>
        <AnimatePresence>
          <Suspense
              fallback={
                <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                  <div className="w-50 mx-auto">
                    Please wait while we load page
                  </div>
                </div>
              }>
            <Switch>
              {isAuthenticated && <Redirect exact from="/" to="/dashboard" />}
              {!isAuthenticated && <Redirect exact from="/" to="/login" />}

              {/* When the user is not logged in */}
              <Route path={['/login',
                // '/forgot', '/signup'
              ]}>
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                      <Route path="/login" component={Login} />
                      {/*
                      TODO: review this in the future
                      <Route path="/forgot" component={Forgot} />
                      <Route path="/signup" component={Signup} />
                      */}
                    </motion.div>
                  </Switch>
                </PresentationLayout>
              </Route>

              {/* When the user is logged in */}
              {isAuthenticated &&
              <Route
                  path={[
                    '/dashboard',
                    '/kpis',
                    '/users',
                    '/products',
                    '/sells',
                    '/products_list'
                  ]}>
                  <LeftSidebar>
                      <Switch location={location} key={location.pathname}>
                          <motion.div
                              initial="initial"
                              animate="in"
                              exit="out"
                              variants={pageVariants}
                              transition={pageTransition}>
                              <Route
                                  path="/dashboard"
                                  component={Dashboard}
                              />
                              <Route
                                  path="/kpis"
                                  component={KPIs}
                              />
                              <Route
                                  path="/users"
                                  component={Users}
                              />
                              <Route
                                  path="/products"
                                  component={Products}
                              />
                              <Route
                                  path="/products_list"
                                  component={ProductsList}
                              />
                              <Route
                                  path="/sells"
                                  component={Sells}
                              />

                          </motion.div>
                      </Switch>
                  </LeftSidebar>
              </Route>
              }

              {/* If the path dont exists returns the NoFound component */}
              <Route
                  path={[
                    '*'
                  ]}>
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                      <Route
                          path="*"
                          component={NotFound}
                      />

                    </motion.div>
                  </Switch>
                </PresentationLayout>
              </Route>

            </Switch>
          </Suspense>
        </AnimatePresence>
      </ThemeProvider>
  )

}

export default Routes;
