import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import _ from "lodash";

import {
  CircularProgress,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, TablePagination
} from '@material-ui/core';
import { TableFooter } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import TablePaginationActions from '../PaginationActions';
import SearchBar from "material-ui-search-bar";

const styles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  rowStyle: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }
}));

type Props = {}

export default function ProductsByBrands(props: Props) {

  const {
    products_by_brands_data,
    products_by_brands_isLoading,
    products_by_brands_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const classes = styles();
  const [formatedData, setFormatedData] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");

  const emptyRows = tableData ? rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage) : 0;

  const handleChangePage = (event:any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatData = (brandsDataList: any) => {
    const tableData = [];
    let noBrandCount = 0;
    brandsDataList.forEach((row: any) => {
      if(row.brand === '' ||  row.brand === null) {
        noBrandCount += row.total;
      }
      else {
        tableData.push(row)
      }
    })
    tableData.push({brand:'No Brand or not specified', total: noBrandCount})
    return _.orderBy(tableData, ['total'],['desc']);
  }

  const requestSearch = (searchedVal: string) => {
    const filteredRows = formatedData.filter((row: any) => {
      return row.brand.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setTableData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    if(products_by_brands_data && products_by_brands_data.data){
      setFormatedData(formatData(products_by_brands_data.data))
      setTableData(formatData(products_by_brands_data.data))
    }
  },[products_by_brands_data])


  if (products_by_brands_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(products_by_brands_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  if(!tableData) {
    return (<></>)
  }
  return (
      <Fragment>
        <SearchBar
            placeholder={'Search brand name'}
            value={searched}
            onChange={(searchVal: any) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
        />
        <TableContainer className="mb-4" component={Paper}>
          <Table aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell className={classes.head}>Brand</TableCell>
                <TableCell className={classes.head} align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData && (rowsPerPage > 0
                      ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tableData
              ).map((row: any) => (
                  <TableRow key={'__'+row.brand} className={classes.rowStyle}>
                    <TableCell component="th" scope="row">
                      <b>{row.brand}</b>
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.total}
                    </TableCell>
                  </TableRow>
              ))}

              {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={tableData? tableData.length: 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{  inputProps: { 'aria-label': 'rows per page' },  native: true }}
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

      </Fragment>
  )
}
