import React, {Fragment, useEffect} from 'react';
import {useDispatch} from "react-redux";
import { Grid } from '@material-ui/core';

import {PageTitle, WrapperSimple} from "../../components";
import { ProductsListTable } from "../../tables";

import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";

/*
  Products List page
 */
export default function ProductsList() {

  const dispatch = useDispatch();

  // Getting the data from the API
  useEffect(() => {
    dispatch(dataRequestActions.getProductsProcessedToday())
    dispatch(dataRequestActions.getProducts())
  }, [])

  return (
      <Fragment>
        <PageTitle
            titleHeading="Products List"
            titleDescription="Products List"
        />

        <Grid container spacing={4} justify={"center"}>
          <Grid item xs={12}>
            <WrapperSimple sectionHeading="Products list">
              <ProductsListTable />
            </WrapperSimple>
          </Grid>

        </Grid>
      </Fragment>
  )
}
