import {makeStyles} from "@material-ui/core/styles";
import { FieldArrayRenderProps } from "formik";
import _ from "lodash";
import React, {Fragment, useEffect, useState} from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import { Button } from "@material-ui/core";
import UploadService from "../../../utilities/Services/UploadService";
import {Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export  interface ISelectImagesProps extends FieldArrayRenderProps {
  isMultiple: boolean;
  label?: string;
  onImageSelect: (key: string) => void;
  images?: any[];
  error?: boolean;
  helperText?: string;
}

type Props = ISelectImagesProps

const styles = makeStyles(theme => ({
  container: {
    paddingTop: '20px',
    fontSize: 20
  },
  button: {
    border: '1px dashed rgba(43, 46, 52, 0.5)',
    padding: '8px 0',
    boxShadow: 'none',
    textTransform: 'none',
  },
  buttonWithError: {
    color: 'red'
  },
  fileImage: {
    width: '300px',
    cursor: 'pointer'
  },
  fileUrl: {
    paddingLeft: 20,
    fontWeight: 'bold',
    alignContent: 'center'
  },
  iconButton:{
    fontSize: 20,
  },
  textButton: {
    fontSize: 12,
    marginBottom: 10,
    marginLeft: 10
  },
  regularImage: {

  },
  selectedImage: {
    backgroundColor: "lightgray"
  },
  imagesContainer: {
    textAlign: "center"
  },
  error: {
    color: "#f83245",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: 3,
    textAlign: "left",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
}
}))

const SelectImages = (props: any) => {

  const imagesUrl = process.env.REACT_APP_IMAGES_BASE_URL

  const { images, label, name, isMultiple, values, onImageSelect, error, helperText } = props;

  const [files, setFiles] = useState<any>();
  const [isSelecting, setIsSelecting ] = useState<boolean>(false);
  const classes = styles();

  useEffect(() => {
    if (images){
      images.forEach( (photo: any) => {
        photo.thumbImg = `${imagesUrl}/thumbnail/${photo.uuid}.jpg`;
        photo.originalImg = `${imagesUrl}/original/${photo.uuid}.jpg`;
      })
      setFiles(images);
    }
  }, []);

  useEffect(() => {
    // window.console.log('useEffect values');
  }, [images]);


  const onImageSelectHandler = ( key: string ) => {
    onImageSelect(key);
    const oldFiles = [...files];
    setFiles(oldFiles);
  }

  const renderImages = () => {
    if(!files){
      return
    }

    return files.map((fileInfo: any, index: number) => {

      const onImageSelectClick =  (event: any) => {
        event.preventDefault();
        onImageSelectHandler(fileInfo.uuid)
        fileInfo.isSelected = !fileInfo.isSelected;
      }

      const isSelected = fileInfo.isSelected ? 'SELECTED' : "NOT SELECTED"
      return <Fragment>
        <Grid item={true} sm={4}
              key={`image_file_${index}`}
              justify="center"
              alignItems="center"
              className={fileInfo.isSelected ? classes.selectedImage : classes.regularImage}
        >
          <Grid container={true}
                style={{padding: 20}}
                direction="row"
                justify="center"
                alignItems="center"

          >
            <Grid item={true} sm={12} className={classes.imagesContainer}>
              <Image src={fileInfo.thumbImg}
                     className={classes.fileImage}
                     onClick={onImageSelectClick}/>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    })
  };

  return <Fragment>
    <div className={classes.container}>
      <Grid container={true}>
        <Grid container={true}>
          <Grid item={true} sm={12}>
            <b>Select the invoice</b>
            <hr/>
            {error && <p className={classes.error}>{helperText}</p>}
          </Grid>
          <Grid item={true} sm={12}>
            <Grid container={true}>
              { renderImages() }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  </Fragment>
}

export default SelectImages;
