import React, { Fragment } from 'react';
import {useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import {CircularProgress, Grid} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function ProductsPerUser() {

  const { products_users_statistic_data,
    products_users_statistic_error,
    products_users_statistic_isLoading } = useSelector((state:IStoreState) => state.dataRequest )

  if(products_users_statistic_isLoading ) {
    return (<CircularProgress/>)
  }
  if(!products_users_statistic_data) {
    return <></>
  }
  if(products_users_statistic_error) {
    return (<Fragment>
      <p>Something when wrong, try again or contact the admin!</p>
    </Fragment>)
  }

  return (
      <Fragment>
        <Grid container spacing={4} className="mt-4">
          <Grid item xs={3}>
            <div className="text-center">
              <div>
                <FontAwesomeIcon
                    icon={['far', 'user']}
                    className="font-size-xxl text-success"
                />
              </div>
              <div className="mt-3 line-height-sm">
                <b className="font-size-lg">{products_users_statistic_data.user_count}</b>
                <span className="text-black-50 d-block">Total users</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="text-center">
              <div>
                <FontAwesomeIcon
                    icon={['far', 'user']}
                    className="font-size-xxl text-info"
                />
              </div>
              <div className="mt-3 line-height-sm">
                <b className="font-size-lg">{products_users_statistic_data.users_with_products}</b>
                <span className="text-black-50 d-block">Users with products</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="text-center">
              <div>
                <FontAwesomeIcon
                    icon={['far', 'file']}
                    className="font-size-xxl text-danger"
                />
              </div>
              <div className="mt-3 line-height-sm">
                <b className="font-size-lg">{products_users_statistic_data.product_count}</b>
                <span className="text-black-50 d-block">Total products</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="text-center">
              <div>
                <FontAwesomeIcon
                    icon={['far', 'chart-bar']}
                    className="font-size-xxl text-info"
                />
              </div>
              <div className="mt-3 line-height-sm">
                <b className="font-size-lg">{products_users_statistic_data.productsByUserMean}</b>
                <span className="text-black-50 d-block">Avg Product by user</span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Fragment>
  )
}
