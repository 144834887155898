import {makeStyles} from "@material-ui/core/styles";
import { FieldArrayRenderProps } from "formik";
import _ from "lodash";
import React, {Fragment, useEffect, useState} from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import { Button } from "@material-ui/core";
import UploadService from "../../../utilities/Services/UploadService";
import {Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export  interface IFileProps extends FieldArrayRenderProps {
  isMultiple: boolean;
  label?: string;
  onRemoveFile: (key: string) => void;
}

type Props = IFileProps

const styles = makeStyles(theme => ({
  container: {
    paddingTop: '20px'
  },
  button: {
    border: '1px dashed rgba(43, 46, 52, 0.5)',
    padding: '8px 0',
    boxShadow: 'none',
    textTransform: 'none',
  },
  buttonWithError: {
    color: 'red'
  },
  fileImage: {
    width: '300px'
  },
  fileUrl: {
    paddingLeft: 20,
    fontWeight: 'bold',
    alignContent: 'center'
  },
  iconButton:{
    fontSize: 20,
  },
  textButton: {
    fontSize: 12,
    marginBottom: 10,
    marginLeft: 10
  },
}))

const FileUpload = (props: any) => {

  const uploadService = new UploadService();
  const { label, name, isMultiple, values, onRemoveFile } = props;

  const [files, setFiles] = useState<any>();
  const [isUploading, setIsUploading ] = useState<boolean>(false);
  const [error, setError ] = useState<any>();
  const classes = styles();


  useEffect(() => {
    // window.console.log('useEffect ', values);
  }, [values]);

  const submitFile = async (event: any) => {
    event.preventDefault();
    const fileList: File[] = [];
    for (const f of event.target.files) {
      fileList.push(f);
    }
    await fileList.forEach((file, index) => {
      const formData = new FormData();
      formData.append('file', file);
      setIsUploading(true)
      uploadService.uploadFile(formData)
          .then(response => {
            uploadService.getFile(response.data.Key, 'THUMBNAIL')
                .then(fileResponse => {
                  values.push({
                    key: response.data.Key,
                    signedUrl: fileResponse.data,
                    uploadFileName: file.name
                  })
                  setFiles(values);
                  if(index === values.length-1){
                    setIsUploading(false)
                  }
                });
          })
          .catch(error => {
            setIsUploading(false)
            setError({error})
          })
    });
  }

  const removeFileHandler = async ( key: string ) => {
    setIsUploading(true);
    await onRemoveFile(key);
    setIsUploading(false);
  }

  const renderImages = () => {
    return files.map((fileInfo: any, index: number) => {
      return <Fragment>
        <Grid item={true} sm={12}
              key={`image_file_${index}`}
        >
          <Grid container={true}
                style={{padding: 20}}
                justify="center"
                alignItems="center">
            <Grid item={true} sm={6}>
              <Image src={fileInfo.signedUrl} className={classes.fileImage}/>
            </Grid>
            <Grid item={true} sm={5}>
              <p className={classes.fileUrl}>{fileInfo.uploadFileName}</p>
            </Grid>
            <Grid item={true} sm={1}>
              <p>
                <span onClick={() => removeFileHandler(fileInfo.key)} className={classes.iconButton}>
                  <FontAwesomeIcon icon={['fas', 'trash']} />
                </span>
                <span className={classes.textButton}>
                  Delete
                </span>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    })
  };

  const showUploadButton = () => {
    return isMultiple || (!isMultiple && values.length == 0);
  }

  return <Fragment>
    <div className={classes.container}>
      <Grid container={true}>
        {isUploading && <CircularProgress />}
        {!isUploading && files && renderImages() }
      </Grid>

      {
        showUploadButton() &&
        <Button
            fullWidth={true}
            variant="contained"
            component="label"
            color="default"
            className={`${classes.button}`}
        >

            <Fragment>
              {label ? label : 'Upload your images'}
                <input id={`file_${name}`}
                       disabled={isUploading}
                       name={`file_${name}`}
                       type="file"
                       multiple={isMultiple}
                       onChange={submitFile}
                       style={{display: 'none'}}
                       accept="image/*"/>
            </Fragment>

        </Button>
      }
    </div>
  </Fragment>
}

export default FileUpload;
