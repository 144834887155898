import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {IStoreState} from "../../../stores/createStore";
import {
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";
import {Alert} from "@material-ui/lab";


const styles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red'
  },
  card: {
    marginBottom: 20,
  },
  cardHeader: {
    backgroundColor: 'lightgray',
    fontSize: 18,
    fontWeight: 'bold'
  },
  table: {
    minWidth: 650,
  },
  deleteButton: {
    backgroundColor: "red",
    color: "white",
    fontWeight: "bolder",
    "&:hover": {
      backgroundColor: "darkred",
    },
    minWidth: 115,
    marginBottom: 10
  },
  sendPushButton: {
    backgroundColor: "gray",
    color: "white",
    fontWeight: "bolder",
    "&:hover": {
      backgroundColor: "darkgray",
    },
    minWidth: 115,
    marginBottom: 10,
    marginRight: 10
  }
}));

type Props = {
  selectedProduct?: any
}

export default function AddProductVideo (props: Props) {

  const classes = styles();

  const {
    product_videos_data,
    product_videos_error,
    product_videos_isLoading,
    send_video_push,
    send_video_push_error,
    send_video_push_isLoading,
    delete_youtube_video_isLoading,
    delete_youtube_video
  } = useSelector((state:IStoreState) => state.dataRequest )

  const dispatch = useDispatch();

  const [pushNotification, setPushNotification] = useState<string>("");

  const sendPushEvent = (videoId: number) => {
    if(props.selectedProduct){
      // Sending the push call to the API
      setPushNotification("");
      dispatch(dataRequestActions.sendVideoProductPush(videoId));
    }
  }

  const deleteVideo = (videoId: string) => {

    if(props.selectedProduct){
      const deleteObj = {
        videoId: videoId,
        userId: props.selectedProduct.userID,
        productId: props.selectedProduct.id
      };
      dispatch(dataRequestActions.deleteYoutubeVideo(deleteObj));
    }
  }

  const showVideosTable = () => {
    return(
        <Fragment>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>VideoID</TableCell>
                <TableCell >Title</TableCell>
                <TableCell >Video</TableCell>
                <TableCell >Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {product_videos_data.data.map((row: any) => {

                let sendPushButton;
                let iframeSource;

                if(!row.YoutubeVideos) {
                  iframeSource = "";
                }
                else if(row.type === 'zenown'){
                  row.title = "ZenOwn Video - " + row.YoutubeVideos.title
                  row.videoID = 1;
                  iframeSource = <iframe height="150" src={`https://www.youtube.com/embed/${row.YoutubeVideos.videoID }`}></iframe>
                }
                else {
                  row.title = row.YoutubeVideos.title
                  row.videoID = row.YoutubeVideos.videoID;

                  sendPushButton = <Button className={classes.sendPushButton}
                                           onClick={() => sendPushEvent(row.id)}>Send Push</Button>;
                  iframeSource = <iframe height="150" src={`https://www.youtube.com/embed/${row.videoID }`}></iframe>
                }

                return (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        <b>{row.videoID}</b>
                      </TableCell>
                      <TableCell >{row.title}</TableCell>
                      <TableCell>{iframeSource}</TableCell>
                      <TableCell>
                        {sendPushButton}
                        <Button className={classes.deleteButton}
                                onClick={() => deleteVideo(row.id)}>
                          Delete video</Button>


                      </TableCell>
                    </TableRow>
                )
              })
              }
            </TableBody>
          </Table>
        </Fragment>
    )
  }

  const notificationArea = () => {
    if(send_video_push_isLoading){
      return(
          <Fragment>
            <Alert severity="info">Sending the push!</Alert>
          </Fragment>
      )
    }
    if(delete_youtube_video_isLoading){
      return(
          <Fragment>
            <Alert severity="info">Deleting the video</Alert>
          </Fragment>
      )
    }
    else if(pushNotification === "OK") {
      return(
          <Fragment>
            <Alert severity="success">Push Notification sent, more info in the Last push notifications sent table</Alert>
          </Fragment>
      )
    }
    else if(pushNotification === "ERROR") {
      return(
          <Fragment>
            <Alert severity="error">Something went wrong with the push!</Alert>
          </Fragment>
      )
    }
    return
  }
  useEffect(() => {
    if(product_videos_data){
      setPushNotification("");
    }
  }, [product_videos_data])

  useEffect(() => {
    if(send_video_push){
      const singleResponse = send_video_push.data.singleResponse[0];
      if(singleResponse.success && props.selectedProduct){
        setPushNotification("OK");
        dispatch(dataRequestActions.getPushNotifications(props.selectedProduct.userID));
      }
      else {
        setPushNotification("ERROR");
      }
    }
  },[send_video_push])

  useEffect(()=> {
    if(delete_youtube_video){
      dispatch(dataRequestActions.getProductVideos(props.selectedProduct.id));
    }
  },[delete_youtube_video])


  if (product_videos_isLoading) {
    return <CircularProgress />
  }



  if(product_videos_data){

    if(product_videos_data.data.length === 0) {
      return <Fragment/>
    }
    return (
        <Fragment>
          <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title={"Videos Added"}
            />
            <CardContent>
              {notificationArea()}
              {showVideosTable()}
            </CardContent>
          </Card>
        </Fragment>
    )
  }
  else {
    return <CircularProgress />
  }


};
