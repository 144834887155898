import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import LoginStyle from "../../../styles/containers/loginStyle";
import { useFormik } from 'formik';
import * as yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import * as accountActions from "../../../stores/modules/account/actions";
import {ILoginRequest} from "../../../utilities/Services/UserService";

/*
  The Login component
 */

type Props =  RouteComponentProps<{}>

const LoginForm : React.FC<Props> = (props) => {

  const classes = LoginStyle();
  const dispatch = useDispatch();

  const { isLogged, error, isLoading } = useSelector((state: IStoreState) => state.account);

  const validationSchema = yup.object({
    email: yup
        .string()
        .email()
        .required('Email is required'),
    user_password: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
  });

  const dispatchData = (loginRequest: ILoginRequest) => {
    dispatch(accountActions.login(loginRequest))
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      user_password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const loginRequest: ILoginRequest = {
        email: values.email,
        user_password: values.user_password
      }
      dispatchData(loginRequest)
    },
  });

  if (isLogged) {
    props.history.push('/')
  }

  return (
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            autoComplete="email"
            autoFocus
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
            variant="outlined"
            margin="normal"
            required
            autoComplete="current-password"
            fullWidth
            id="user_password"
            name="user_password"
            label="Password"
            type="password"
            value={formik.values.user_password}
            onChange={formik.handleChange}
            error={formik.touched.user_password && Boolean(formik.errors.user_password)}
            helperText={formik.touched.user_password && formik.errors.user_password}
        />

        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
        >
          Sign In
        </Button>

        {/*
        TODO: Review the forgot and register in the future
        <Grid container>
          <Grid item xs>
            <Link href="/forgot" variant="body2" color={'secondary'}>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signup" variant="body2" color={'secondary'}>
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid> */}
      </form>
  );
}

export default compose<Props, any>(
    withRouter,
)(LoginForm)
