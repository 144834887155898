import React, {Fragment, useEffect} from 'react';
import {useDispatch} from "react-redux";
import { Grid } from '@material-ui/core';
import {PageTitle, WrapperSimple} from "../../components";

import { SellsTable } from "../../tables";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";

/*
  Sells List page
 */

export default function Sells() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dataRequestActions.getSells())
  }, [])

  return (
      <Fragment>
        <PageTitle
            titleHeading="Sells"
            titleDescription="Sells dashboard"
        />

        <Grid container spacing={4} justify={"center"}>
          <Grid item xs={12}>
            <WrapperSimple sectionHeading="Sells">
              <SellsTable />
            </WrapperSimple>
          </Grid>

        </Grid>
      </Fragment>
  )
}
