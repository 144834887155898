import { action } from 'typesafe-actions'
import { IUserIdRequest } from '../../../utilities/Services/DataService';

/*
  Users Data Section
 */
export function usersDataRequest() {
  return action('@@dataRequest/USERS_DATA');
}
export function usersDataRequestSuccess(dataRequested: any) {
  return action('@@dataRequest/USERS_DATA_SUCCESS', {dataRequested});
}
export function usersDataRequestError(error: Error) {
  return action('@@dataRequest/USERS_DATA_ERROR',{error});
}

/*
  Users Android Data Section
 */
export function usersAndroidRequestData() {
  return action('@@dataRequest/USERS_ANDROID_DATA');
}
export function usersAndroidDataRequestSuccess(dataRequested: any) {
  return action('@@dataRequest/USERS_ANDROID_DATA_SUCCESS',{dataRequested});
}
export function usersAndroidDataRequestError(error: Error) {
  return action('@@dataRequest/USERS_ANDROID_DATA_ERROR',{error});
}


/* Product Statistic Data Section */
export function getProductsStatistics() {
  return action('@@dataRequest/PRODUCT_STATISTIC_DATA');
}
export function getProductsStatisticsSuccess(dataRequested: any) {
  return action('@@dataRequest/PRODUCT_STATISTIC_DATA_SUCCESS',{dataRequested});
}
export function getProductsStatisticsError(error: Error) {
  return action('@@dataRequest/PRODUCT_STATISTIC_DATA_ERROR',{error});
}


/* Product and Users Statistic Data Section */
export function getProductsUsersStatistics() {
  return action('@@dataRequest/PRODUCT_USERS_STATISTIC_DATA');
}
export function getProductsUsersStatisticsSuccess(dataRequested: any) {
  return action('@@dataRequest/PRODUCT_USERS_STATISTIC_DATA_SUCCESS',{dataRequested});
}
export function getProductsUsersStatisticsError(error: Error) {
  return action('@@dataRequest/PRODUCT_USERS_STATISTIC_DATA_ERROR',{error});
}


/* Product and Photos Statistic Data Section */
export function getProductsPhotosStatistics() {
  return action('@@dataRequest/PRODUCT_PHOTO_STATISTIC_DATA');
}
export function getProductsPhotosStatisticsSuccess(dataRequested: any) {
  return action('@@dataRequest/PRODUCT_PHOTO_STATISTIC_DATA_SUCCESS',{dataRequested});
}
export function getProductsPhotosStatisticsError(error: Error) {
  return action('@@dataRequest/PRODUCT_PHOTO_STATISTIC_DATA_ERROR',{error});
}


/* Product by Brands Data Section */
export function getProductsByBrands() {
  return action('@@dataRequest/PRODUCT_BY_BRAND_DATA');
}
export function getProductsByBrandsSuccess(dataRequested: any) {
  return action('@@dataRequest/PRODUCT_BY_BRAND_SUCCESS',{dataRequested});
}
export function getProductsByBrandsError(error: Error) {
  return action('@@dataRequest/PRODUCT_BY_BRAND_ERROR',{error});
}

/* Product by Category Data Section */
export function getProductsByCategories() {
  return action('@@dataRequest/PRODUCT_BY_CATEGORY_DATA');
}
export function getProductsByCategoriesSuccess(dataRequested: any) {
  return action('@@dataRequest/PRODUCT_BY_CATEGORY_SUCCESS',{dataRequested});
}
export function getProductsByCategoriesError(error: Error) {
  return action('@@dataRequest/PRODUCT_BY_CATEGORY_ERROR',{error});
}


/* Product by User data Section */
export function getProductsUsersCount() {
  return action('@@dataRequest/PRODUCTS_USERS_DATA');
}
export function getProductsUsersCountSuccess(dataRequested: any) {
  return action('@@dataRequest/PRODUCTS_USERS_DATA_SUCCESS',{dataRequested});
}
export function getProductsUsersCountError(error: Error) {
  return action('@@dataRequest/PRODUCTS_USERS_DATA_ERROR',{error});
}


/* Product by User data Section */
export function getUserProductsList(userId: IUserIdRequest) {
  return action('@@dataRequest/USER_PRODUCTS_LIST_DATA', {userId});
}
export function getUserProductsListClear() {
  return action('@@dataRequest/USER_PRODUCTS_LIST_CLEAR');
}
export function getUserProductsListSuccess(dataRequested: any) {
  return action('@@dataRequest/USER_PRODUCTS_LIST_SUCCESS',{dataRequested});
}
export function getUserProductsListError(error: Error) {
  return action('@@dataRequest/USER_PRODUCTS_LIST_ERROR',{error});
}


/* Product by User data Section */
export function getProducts() {
  return action('@@dataRequest/GET_PRODUCTS');
}
export function getProductsSuccess(dataRequested: any) {
  return action('@@dataRequest/GET_PRODUCTS_SUCCESS',{dataRequested});
}
export function getProductsError(error: Error) {
  return action('@@dataRequest/GET_PRODUCTS_ERROR',{error});
}


/* Product by User data Section */
export function getProductsProcessedToday() {
  return action('@@dataRequest/GET_PRODUCTS_PROCESSED_TODAY');
}
export function getProductsProcessedTodaySuccess(dataRequested: any) {
  return action('@@dataRequest/GET_PRODUCTS_PROCESSED_TODAY_SUCCESS',{dataRequested});
}
export function getProductsProcessedTodayError(error: Error) {
  return action('@@dataRequest/GET_PRODUCTS_PROCESSED_TODAY_ERROR',{error});
}



/* Product by User data Section */
export function getProductsOverallData() {
  return action('@@dataRequest/GET_PRODUCTS_OVERALL_DATA');
}
export function getProductsOverallDataSuccess(dataRequested: any) {
  return action('@@dataRequest/GET_PRODUCTS_OVERALL_DATA_SUCCESS',{dataRequested});
}
export function getProductsOverallDataError(error: Error) {
  return action('@@dataRequest/GET_PRODUCTS_OVERALL_DATA_ERROR',{error});
}


/* Get Sells */
export function getSells() {
  return action('@@dataRequest/GET_SELLS');
}
export function getSellsSuccess(dataRequested: any) {
  return action('@@dataRequest/GET_SELLS_SUCCESS',{dataRequested});
}
export function getSellsError(error: Error) {
  return action('@@dataRequest/GET_SELLS_ERROR',{error});
}


/* Get user Push Notifications */
export function getPushNotifications(userId: IUserIdRequest) {
  return action('@@dataRequest/GET_USER_PUSH_NOTIFICATIONS', {userId});
}
export function getPushNotificationsSuccess(dataRequested: any) {
  return action('@@dataRequest/GET_USER_PUSH_NOTIFICATIONS_SUCCESS',{dataRequested});
}
export function getPushNotificationsError(error: Error) {
  return action('@@dataRequest/GET_USER_PUSH_NOTIFICATIONS_ERROR',{error});
}


/* Get Product videos */
export function getProductVideos(productId: number) {
  return action('@@dataRequest/GET_PRODUCT_VIDEOS', {productId});
}
export function getProductVideosSuccess(dataRequested: any) {
  return action('@@dataRequest/GET_PRODUCT_VIDEOS_SUCCESS',{dataRequested});
}
export function getProductVideosError(error: Error) {
  return action('@@dataRequest/GET_PRODUCT_VIDEOS_ERROR',{error});
}

/* Sending Video Product Push */
export function sendVideoProductPush(sendPushId: number) {
  return action('@@dataRequest/SEND_VIDEO_PRODUCT_PUSH', {sendPushId});
}

export function sendVideoProductPushSuccess(data: any) {
  return action('@@dataRequest/SEND_VIDEO_PRODUCT_PUSH_SUCCESS',{data});
}
export function sendVideoProductPushError(error: Error) {
  return action('@@dataRequest/SEND_VIDEO_PRODUCT_PUSH_ERROR',{error});
}


export function addYoutubeVideo(addYoutubeVideoRequest: any) {
  return action('@@dataRequest/ADD_YOUTUBE_VIDEO', {addYoutubeVideoRequest});
}
export function addYoutubeVideoSuccess(dataRequested: any) {
  return action('@@dataRequest/ADD_YOUTUBE_VIDEO_SUCCESS', {dataRequested});
}
export function addYoutubeVideoError(error: Error) {
  return action('@@dataRequest/ADD_YOUTUBE_VIDEO_ERROR',{error});
}



export function deleteYoutubeVideo(deleteYoutubeVideoRequest: any) {
  return action('@@dataRequest/DELETE_YOUTUBE_VIDEO', {deleteYoutubeVideoRequest});
}
export function deleteYoutubeVideoSuccess(dataRequested: any) {
  return action('@@dataRequest/DELETE_YOUTUBE_VIDEO_SUCCESS', {dataRequested});
}
export function deleteYoutubeVideoError(error: Error) {
  return action('@@dataRequest/DELETE_YOUTUBE_VIDEO_ERROR',{error});
}


export function kpiData(kpiDataRequest: any) {
  return action('@@dataRequest/KPI_DATA', {kpiDataRequest});
}
export function kpiDataSuccess(dataRequested: any) {
  return action('@@dataRequest/KPI_DATA_SUCCESS', {dataRequested});
}
export function kpiDataError(error: Error) {
  return action('@@dataRequest/KPI_DATA_ERROR',{error});
}

