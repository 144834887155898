import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import _ from "lodash";

import {
  CircularProgress,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination, Grid, Button, IconButton
} from '@material-ui/core';
import { TableFooter } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import TablePaginationActions from '../PaginationActions';
import SearchBar from "material-ui-search-bar";
import * as dataRequestActions from "../../../stores/modules/dataRequest/actions";
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';

const styles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  rowStyle: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    height: 72
  },
  closeInfo: {
    textAlign: 'right',
    height: 48,
    marginTop:10
  },
  userInfoHeader:{
    height: 48 ,
    textAlign: 'center',
    fontWeight: 'bolder',
    alignContent: 'center',
  },
  userInfoText:{
    marginTop: 20
  },
  searchBar: {
    marginLeft: 20
  }
}));

type Props = {}

export default function UsersList () {
  const {
    user_products_list_data,
    user_products_list_isLoading,
    user_products_list_error
  } = useSelector((state:IStoreState) => state.dataRequest )

  const dispatch = useDispatch();

  const classes = styles();
  const [formatedData, setFormatedData] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const emptyRows = tableData ? rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage) : 0;

  /* User Products Section */
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);
  const [userId, setUserId] = React.useState<number|undefined>();


  const handleChangePage = (event:any, newPage: any) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatData = (userProductsData: any) => {
    setUserId(userProductsData[0].userID)
    return _.orderBy(userProductsData, ['id'],['asc']);
  }

  const requestSearch = (searchedVal: string) => {
    const filteredRows = formatedData.filter((row: any) => {

      if(row.id && row.id === parseInt(searchedVal)){
        return true;
      }
      if(row.price && row.price === parseFloat(searchedVal)){
        return true;
      }

      if(row.name && row.name.toString().includes(searchedVal)){
        return true;
      }
      if(row.brand && row.brand.toString().includes(searchedVal)){
        return true;
      }
      if(row.model && row.model.toString().includes(searchedVal) ){
        return true;
      }
      if(row.category && row.category.toString().includes(searchedVal) ){
        return true;
      }
      if ( row.date &&  moment(row.date).format('YYYY/MM/DD').includes(searchedVal)){
        return true
      }

      return false

    });
    setTableData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    if(user_products_list_data && user_products_list_data.data){
      setFormatedData(formatData(user_products_list_data.data))
      setTableData(formatData(user_products_list_data.data))
    }
  },[user_products_list_data])

  const closeUserProductList = () => {
    cancelSearch();
    // Clean the user product list
    setShowUserInfo(false);
    dispatch(dataRequestActions.getUserProductsListClear());
  }

  if (user_products_list_isLoading) {
    return (
        <Fragment>
          <CircularProgress />
        </Fragment>
    )
  }

  if(user_products_list_error) {
    return (
        <Fragment>
          <p>Something when wrong, try again or contact the admin!</p>
        </Fragment>
    )
  }

  if(!tableData) {
    return (<></>)
  }
  return (
      <Fragment>

        <Grid container spacing={2} >
          <Grid item xs={11} >
            <Grid container className={classes.userInfoHeader}>
              <Grid item xs={2} className={classes.userInfoText}>
                User {userId} products list
              </Grid>
              <Grid item xs={10} >
                <SearchBar
                    placeholder={'Search'}
                    value={searched}
                    onChange={(searchVal: any) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    className={classes.searchBar}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.closeInfo}>
            <IconButton
                onClick={closeUserProductList}
                aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <TableContainer className="mb-4" component={Paper}>
              <Table aria-label="simple table" >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head}>Id</TableCell>
                    <TableCell className={classes.head}>Name</TableCell>
                    <TableCell className={classes.head}>Brand</TableCell>
                    <TableCell className={classes.head}>Model</TableCell>
                    <TableCell className={classes.head}>Category</TableCell>
                    <TableCell className={classes.head} align="right">Price</TableCell>
                    <TableCell className={classes.head}>Currency</TableCell>
                    <TableCell className={classes.head}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData && (rowsPerPage > 0
                          ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : tableData
                  ).map((row: any) => (
                      <TableRow key={'__product_'+row.id} className={classes.rowStyle}>

                        <TableCell component="th" scope="row">
                          <b>{row.id}</b>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <b>{row.name}</b>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <b>{row.brand}</b>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <b>{row.model}</b>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <b>{row.category}</b>
                        </TableCell>
                        <TableCell align="right">
                          {row.price}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <b>{row.currency}</b>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <b>{moment(row.date).format('YYYY/MM/DD')}</b>
                        </TableCell>
                      </TableRow>
                  ))}

                  {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        count={tableData? tableData.length: 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

      </Fragment>
  )
}
