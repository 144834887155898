// src/views/old_components/login/LoginForm.tsx

import * as React from 'react';
import {compose} from 'recompose';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import LoginStyle from "../../../styles/containers/loginStyle";
import * as yup from "yup";
import {ILoginRequest, ISignupRequest} from "../../../utilities/Services/UserService";
import * as accountActions from "../../../stores/modules/account/actions";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {IStoreState} from "../../../stores/createStore";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {useEffect, useState} from "react";

type Props = RouteComponentProps<{}>

const SignupForm : React.FC<Props> = (props) => {

  const classes = LoginStyle();
  const dispatch = useDispatch();

  const { accountRegister, isLogged, error, isLoading } = useSelector((state: IStoreState) => state.account);
  const [signupDone, setSignupDone] = useState<boolean>(false);
  const validationSchema = yup.object({
    user_name: yup
        .string()
        .required('User name is required'),
    user_email: yup
        .string()
        .email()
        .required('Email is required'),
    password: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    confirm_password: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const dispatchData = (signupRequest: ISignupRequest) => {
    dispatch(accountActions.signup(signupRequest))
  }

  const formik = useFormik({
    initialValues: {
      user_name: '',
      user_email: '',
      password: '',
      confirm_password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const signupRequest: ISignupRequest = {
        user_email: values.user_email,
        user_name: values.user_name,
        password: values.password,
        confirm_password: values.confirm_password
      }
      console.log('SENDING')
      dispatchData(signupRequest)
    },
  });

  if(isLogged){
    props.history.push('/')
  }

  useEffect( () => {
    if(accountRegister){
      setSignupDone(true)
    }
  },[accountRegister])

  if(signupDone){
    return (
        <div>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2" color={'primary'}>
                <h2>Your signup is done, please Sign in</h2>
              </Link>
            </Grid>
          </Grid>
        </div>
    )
  }

  return (
      <form className={classes.form}  onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
                variant="outlined"
                margin="normal"
                autoComplete="user_name"
                fullWidth
                id="user_name"
                name="user_name"
                label="User Name"
                value={formik.values.user_name}
                onChange={formik.handleChange}
                error={formik.touched.user_name && Boolean(formik.errors.user_name)}
                helperText={formik.touched.user_name && formik.errors.user_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                variant="outlined"
                margin="normal"
                autoComplete="user_email"
                fullWidth
                id="user_email"
                name="user_email"
                label="Email Address"
                value={formik.values.user_email}
                onChange={formik.handleChange}
                error={formik.touched.user_email && Boolean(formik.errors.user_email)}
                helperText={formik.touched.user_email && formik.errors.user_email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                variant="outlined"
                margin="normal"

                autoComplete="password"

                fullWidth
                type="password"
                id="password"
                name="password"
                label="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                variant="outlined"
                margin="normal"

                autoComplete="confirm_password"

                fullWidth
                type="password"
                id="confirm_password"
                name="confirm_password"
                label="Confirm password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
            />
          </Grid>
          {/*
          <Grid item xs={12}>
            <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
            />
          </Grid>
          */}
        </Grid>
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
        >
          Sign Up
        </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <Link href="/login" variant="body2" color={'secondary'}>
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </form>
  );
}

export default compose<Props, any>(
    withRouter,
)(SignupForm)
